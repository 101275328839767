'use client'

import Head from 'next/head'
import React from 'react'
import { Thing, WithContext } from 'schema-dts'

const StructuredData: React.FC<{ data: WithContext<Thing> }> = ({ data }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(data),
        }}
      ></script>
    </Head>
  )
}

export default StructuredData
