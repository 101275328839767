import types from '@havppen/gql/types'
import { GetStaticPropsContext } from 'next'

const APP_ID = process.env.APP_ID
const isPreviewMode = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
export const getAppIdByDefaultHost = (host?: string | null) => {
  if (!host) return null

  const baseDomainName = process.env.NEXT_PUBLIC_DEFAULT_APP_ROOT_DOMAIN || ''
  if (host.endsWith(baseDomainName)) {
    const appId = host.split(`.${baseDomainName}`)[0]
    if (isPreviewMode) {
      return appId.replace('.dev', '')
    }

    return appId
  } else if (host === process.env.NEXT_PUBLIC_APP_APPLY_DOMAIN_NAME) {
    return 'admin'
  }

  return null
}

export const fetchAppIdByHost = async (host?: string | null) => {
  if (APP_ID) return APP_ID
  if (!host) return null

  let appId: string | null = getAppIdByDefaultHost(host)
  if (appId) return appId

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/app_id/${host}`, {
    next: {
      revalidate: false,
      tags: [`app_domain:${host}`],
    },
  })
  const data: types.GET_APP_ID = await response.json()
  appId = data?.app_domain_by_pk?.app_id ?? null
  return appId
}

export const getAppIdInContextParams = (params: GetStaticPropsContext['params']) => {
  if (APP_ID) return APP_ID

  const appId = (params?.appId ?? 'default') as string
  return appId
}
