import types from '@havppen/gql/types'

export const MemberRoles = [
  'admin',
  'site-admin',
  'manager',
  'marketer',
  'editor',
  'author',
  'user',
  'anonymous',
] as const
export type MemberRoleType = typeof MemberRoles[number]

export const MEMBER_SEXES = ['m', 'f', 'o'] as const
export type MemberSexType = typeof MEMBER_SEXES[number]

export type MemberPublicBriefProps = {
  id: string
  name: string | null
  username: string | null
  displayName: string | null
  avatarUrl: string | null
}

export type MemberPublicProps = {
  id: string
  name: string | null
  displayName: string | null
  username: string | null
  avatarUrl: string | null
  isPrivate?: boolean | null
}

export type MemberJWTProps = {
  id: string
  memberId: string
  name: string | null
  displayName: string | null
  username: string | null
  email: string | null
  avatarUrl: string | null
  confirmedEmailAt: string | null
  lastLoginAt: string | null
  appId: string
  role: MemberRoleType
}

export const mapMemberPublicFromGql: (
  member: Omit<types.MEMBER_PUBLIC_COLUMNS, '__typename'> | null | undefined,
  options?: { defaultAvatarUrl?: string | null },
) => MemberPublicProps | null = (member, options) => {
  return member
    ? {
        id: member.id as string,
        name: member.name,
        displayName: member.display_name || member.name,
        username: member.username,
        avatarUrl: member.avatar_url || options?.defaultAvatarUrl || null,
        isPrivate: member.is_private || null,
      }
    : null
}

export type MemberPrivateProps = {
  id: string
  name: string | null
  displayName: string | null
  avatarUrl: string | null
  appId: string
  role: MemberRoleType
  username: string | null
  email: string | null
  phone: string | null
  sex: string | null
  birthday: string | null
  unconfirmedEmail: string | null
  unconfirmedPhone: string | null
  confirmedEmailAt: string | null
  confirmedPhoneAt: string | null
  lastLoginAt: string | null
  revokedAt: string | null
}

type MemberPrivateFromGqlOptions = {
  defaultAvatarUrl?: string | null
  overridePhone?: string | null
  overrideEmail?: string | null
  overrideName?: string | null
}
export function mapMemberPrivateFromGql(member: null, options?: MemberPrivateFromGqlOptions): null
export function mapMemberPrivateFromGql(
  member: Omit<types.MEMBER_PRIVATE_COLUMNS, '__typename'>,
  options?: MemberPrivateFromGqlOptions,
): MemberPrivateProps
export function mapMemberPrivateFromGql(
  member: Omit<types.MEMBER_PRIVATE_COLUMNS, '__typename'> | null,
  options?: MemberPrivateFromGqlOptions,
): MemberPrivateProps | null
export function mapMemberPrivateFromGql(
  member: Omit<types.MEMBER_PRIVATE_COLUMNS, '__typename'> | null,
  options?: MemberPrivateFromGqlOptions,
): MemberPrivateProps | null {
  return member
    ? {
        id: member.id,
        name: options?.overrideName || member.display_name || member.name,
        displayName: member.display_name || member.name,
        username: member.username,
        avatarUrl: member.avatar_url || options?.defaultAvatarUrl || null,
        email: options?.overrideEmail || member.email,
        unconfirmedEmail: member.unconfirmed_email,
        confirmedEmailAt: member.confirmed_email_at,
        phone: options?.overridePhone || member.phone,
        sex: member.sex,
        birthday: member.birthday,
        unconfirmedPhone: member.unconfirmed_phone,
        confirmedPhoneAt: member.confirmed_phone_at,
        revokedAt: member.revoked_at,
        lastLoginAt: member.login_at,
        appId: member.app_id,
        role: member.role as MemberRoleType,
      }
    : null
}

export type MemberAuthProps = MemberPrivateProps & {
  memberId: string
  passwordHash: string | null
}

export const mapMemberAuthFromGql: (
  member: Omit<types.MEMBER_AUTH_COLUMNS, '__typename'> | null,
  options?: { defaultAvatarUrl?: string | null },
) => MemberAuthProps | null = (member, options) => {
  return member
    ? {
        id: member.id,
        memberId: member.id,
        name: member.display_name || member.name,
        displayName: member.display_name || member.name,
        username: member.username,
        avatarUrl: member.avatar_url || options?.defaultAvatarUrl || null,
        email: member.email,
        unconfirmedEmail: member.unconfirmed_email,
        confirmedEmailAt: member.confirmed_email_at,
        phone: member.phone,
        unconfirmedPhone: member.unconfirmed_phone,
        confirmedPhoneAt: member.confirmed_phone_at,
        sex: member.sex,
        birthday: member.birthday,
        lastLoginAt: member.login_at,
        revokedAt: member.revoked_at,
        appId: member.app_id,
        role: member.role as MemberRoleType,
        passwordHash: member.password_hash,
      }
    : null
}
