import { ApolloClient, InMemoryCache, NormalizedCacheObject, Operation } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { message } from 'antd'
import merge from 'deepmerge'
import { print } from 'graphql'
import { isEqual, throttle } from 'lodash-es'
import { AuthLinkProps, createApolloLink } from 'src/utils/graphql'

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null

export const captureGraphQLError = (error: any, operation: Operation) => {
  const operationName = operation.operationName
  const operationVariables = operation.variables
  const definition = operation.query.definitions.find(q => q.kind === 'OperationDefinition')
  const operationQuery = definition ? definition.loc?.source.body ?? print(definition) : null

  captureException(error, scope => {
    scope.setTag('kind', operationName)
    scope.setExtra('variables', operationVariables)
    if (operationQuery) scope.setExtra('query', operationQuery)

    if (error.path) {
      scope.addBreadcrumb({
        category: 'query-path',
        message: error.path.join(' > '),
        level: 'debug',
      })
    }

    return scope
  })
}

export const onCaptureInvalidJWT = throttle(() => {
  if (typeof window === 'undefined') return

  message.info('連線已過期，將重新整理此畫面')
  fetch('/api/clear-cookie', {
    method: 'POST',
    credentials: 'include',
  }).finally(() => {
    setTimeout(() => window.location.reload(), 1000)
  })
}, 10_000)

export const createApolloClient = (options: AuthLinkProps = {}) => {
  const apolloClient = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createApolloLink(options, {
      onCaptureGraphQLError: captureGraphQLError,
      onCaptureNetworkError(error) {
        captureException(error, scope => {
          if (error && 'result' in error) {
            scope.setExtra('result', error.result)
          } else if (error && 'bodyText' in error) {
            scope.setExtra('bodyText', error.bodyText)
          }

          return scope
        })
      },
      onInvalidJWTError: onCaptureInvalidJWT,
    }),
    cache: new InMemoryCache(),
  })

  return apolloClient
}

export const initializeApollo = (initialState: { [key: string]: any } | null = null, options?: AuthLinkProps) => {
  const apolloClient = globalApolloClient ?? createApolloClient(options)

  if (initialState) {
    const existingCache = apolloClient.extract()
    const cache = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    apolloClient.cache.restore(cache)
  }

  if (typeof window === 'undefined') {
    if (options?.appId) {
      globalApolloClient = apolloClient
    }
    return apolloClient
  }

  if (!globalApolloClient && options?.authToken) {
    globalApolloClient = apolloClient
  }

  return apolloClient
}
