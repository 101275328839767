type BroadcastChannelEvent = {
  type: string
  data: any
}

class BroadcastChannelClient {
  channelName: string
  channel: BroadcastChannel | null = null

  constructor(channelName: string) {
    this.channelName = channelName
    if (typeof window === 'undefined') return
  }

  public initialize = async () => {
    if (!window.BroadcastChannel) {
      await require('broadcastchannel-polyfill')
    }

    const channel = new window.BroadcastChannel(this.channelName)
    channel.onmessage = this.onMessage
    this.channel = channel
  }

  onMessage = (event: MessageEvent<BroadcastChannelEvent>) => {}
  postMessage = (type: string, data: any) => {
    this.channel?.postMessage({
      type,
      data,
    })
  }
}

export default BroadcastChannelClient
