'use client'

import { InvoiceProps } from '@havppen/types/src/orderRecord'
import { localStore } from '@havppen/utils/src/storageFactory'
import { Form, FormInstance } from 'antd'
import React, { useEffect } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { useDefaultPaymentMethod } from 'src/hooks/checkout'
import { useMember } from 'src/hooks/member.client'

export const INVOICE_LOCAL_STORAGE_KEY = 'invoice.code'
export const PAYMENT_METHOD_LOCAL_STORAGE_KEY = 'payment.method'
const InitialCheckoutForm: React.FC<React.PropsWithChildren<{ form: FormInstance }>> = ({ form }) => {
  const { currentMemberId } = useAuth()
  const { member } = useMember(currentMemberId)

  const defaultPaymentMethod = useDefaultPaymentMethod()

  useEffect(() => {
    const cachedInvoice = JSON.parse(localStore.getItem(INVOICE_LOCAL_STORAGE_KEY) || '{}')
    const cachedPaymentMethod = localStore.getItem(PAYMENT_METHOD_LOCAL_STORAGE_KEY)
    const defaultMemberInvoice: InvoiceProps | undefined = member?.metadata?.invoice

    form.setFieldsValue({
      invoice: {
        invoiceType: 'eInvoice',
        invoiceCarrierType: 'memberCarrier',
        ...defaultMemberInvoice,
        ...cachedInvoice,
        name: cachedInvoice.name ?? defaultMemberInvoice?.name ?? member?.name,
        email: cachedInvoice.email ?? defaultMemberInvoice?.email ?? member?.email ?? member?.unconfirmedEmail,
      },
      paymentMethod: cachedPaymentMethod ?? defaultPaymentMethod,
    })
  }, [form, member, defaultPaymentMethod])

  return (
    <Form
      form={form}
      onValuesChange={(_, values) => {
        localStore.setItem(INVOICE_LOCAL_STORAGE_KEY, JSON.stringify(values.invoice || {}))
        localStore.setItem(PAYMENT_METHOD_LOCAL_STORAGE_KEY, values.paymentMethod)
      }}
    />
  )
}

export default InitialCheckoutForm
