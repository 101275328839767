import { DiscountUnit } from '@havppen/types/src/discount'
import {
  ProductDiscountBaseLimitUnit,
  ProductDiscountProps,
  ProductDiscountType,
  ProductProps,
} from '@havppen/types/src/product'

export const mapProductFromGql: (
  product: {
    id: string | null
    name: string | null
    type: string | null
    path: string | null
    target: string | null
    member_id: string | null
    author_id: string | null
    list_price: number
    featured_image_url: string | null
    fundraising_due_at: string | null
  } | null,
) => ProductProps | null = product => {
  return product
    ? {
        id: product.id as string,
        name: product.name,
        type: product.type,
        path: product.path,
        target: product.target,
        memberId: product.member_id,
        authorId: product.author_id,
        listPrice: product.list_price,
        featuredImageUrl: product.featured_image_url,
        fundraisingDueAt: product.fundraising_due_at ? new Date(product.fundraising_due_at) : null,
      }
    : null
}

export const mapProductDiscountFromGql: (
  product_discounts: {
    id: any | null
    name: string | null
    type: string | null
    target_unit: string | null
    target_amount: any | null
    start_at: any | null
    end_at: any | null
    base_limit_unit: string | null
    base_limit_amount: any | null
    metadata?: any | null
  }[],
) => ProductDiscountProps[] = product_discounts => {
  return product_discounts
    .map(product_discount => ({
      id: product_discount.id,
      type: product_discount.type as ProductDiscountType,
      name: product_discount.name,
      startAt: product_discount.start_at ? new Date(product_discount.start_at) : null,
      endAt: product_discount.end_at ? new Date(product_discount.end_at) : null,
      targetUnit: product_discount.target_unit as DiscountUnit,
      targetAmount: product_discount.target_amount,
      baseLimitUnit: product_discount.base_limit_unit as ProductDiscountBaseLimitUnit,
      baseLimitAmount: product_discount.base_limit_amount,
      metadata: product_discount.metadata,
    }))
    .filter(productDiscount => productDiscount.targetAmount !== null)
    .filter(discount => {
      const now = new Date()
      const isStarted = discount.startAt ? now >= discount.startAt : true
      const isEnded = discount.endAt ? now < discount.endAt : true
      return isStarted && isEnded
    })
    .sort((a, b) => (a.startAt?.getTime() || 0) - (b.startAt?.getTime() || 0))
}
