'use client'

import { ApolloProvider } from '@apollo/client'
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { initializeApolloClient } from 'src/helpers/apollo'
import { initializeTrpcClient, trpc } from 'src/helpers/trpc'

export const ApiProvider: React.FC<
  React.PropsWithChildren<{
    appId: string | undefined
    initialState?: { [key: string]: any } | null
    trpcState?: DehydratedState
  }>
> = ({ appId, initialState, trpcState, children }) => {
  const apolloClient = useMemo(() => {
    return initializeApolloClient(initialState, { appId })
  }, [appId, initialState])

  const trpcClient = useMemo(() => {
    return initializeTrpcClient({ appId })
  }, [appId])

  const queryClient = useMemo(() => new QueryClient(), [])

  const hydratedState = trpc.useDehydratedState(trpcClient, trpcState)

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={hydratedState}>
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        </Hydrate>
      </QueryClientProvider>
    </trpc.Provider>
  )
}
