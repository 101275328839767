'use client'

import { isBrightColor, shadeHexColor } from '@havppen/utils/src/color'
import { ConfigProvider, ThemeConfig } from 'antd'
import dynamic from 'next/dynamic'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { useApp } from './AppContext'
import PreviewContext from './PreviewContext'
const NextProgress = dynamic(() => import('../components/common/NextNProgress'), { ssr: false })

export type ThemeProps = { [key: string]: string | null }

const initialThemeProps = {
  'primary-color': '#1677ff',
  'secondary-color': '#343a40',
  'success-color': '#52c41a',
  'warning-color': '#faad14',
  'error-color': '#f5222d',
  'info-color': '#1677ff',
  'highlight-text-color': '#ffffff',
  'default-text-color': '#000000',
  'default-text-color-light-solid': '#ffffff',
  'title-color': '#000000',
  'title-color-hover': null,
  'link-color': '#1890ff',
  'link-color-hover': null,
  'text-color': '#707070',
  'light-gray-color': '#f1f1f1',
  'fundraising-color': '#c342dc',
  'unpaid-color': '#c342dc',
  'primary-background-text-color': '#000000',
  'primary-background-image-invert-filter': 'invert(1)',
  'background-color': null,
  'component-background-color': '#ffffff',
  'component-text-color': '#222222',
  'border-color': '#E5E7EB',
  'box-shadow-color': 'rgba(0, 0, 0, 0.1)',
  'footer-background-color': '#000000',
  'footer-color': '#fff',
  'header-background-color': '#ffffff',
  'header-color': 'rgba(0, 0, 0, .85)',
  'input-background-color': '#f1f1f1',
  'auth-background-color': null,
  'blur-image-background-color': 'rgba(0, 0, 0, 0.2)',
  'player-primary-color': '#1677ff',
  'blur-image-background-url': null,
  'logo-url': null,
  'logo-white-url': null,
  'favicon-url': null,
  'background-image-url': null,
}

type CustomThemeContextProps = {
  theme: ThemeProps
  headerHeight: number
  headerBlockHeight: number
  isHeaderVisible: boolean
  isHeaderTransparent: boolean
  setHeaderHeight?: (height: number) => void
  setIsHeaderVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setIsHeaderTransparent?: React.Dispatch<React.SetStateAction<boolean>>
  footerHeight: number
  setFooterHeight?: (height: number) => void
}
const defaultValues: CustomThemeContextProps = {
  theme: initialThemeProps,
  headerHeight: 65,
  headerBlockHeight: 65,
  isHeaderVisible: true,
  isHeaderTransparent: false,
  footerHeight: 100,
}
export const CustomThemeContext = createContext<CustomThemeContextProps>(defaultValues)

export const CustomThemeProvider: React.FC<React.PropsWithChildren<{ defaultThemeColors?: ThemeProps }>> = ({
  defaultThemeColors = {},
  children,
}) => {
  const { themeColors, logoWhiteUrl, logoUrl, faviconUrl, settings } = useApp()

  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(false)

  const basicTheme = useMemo(() => {
    const basicTheme = {
      ...initialThemeProps,
      ...defaultThemeColors,
      ...themeColors,
    }
    const primaryTextColor = isBrightColor(basicTheme['primary-color']) ? '#000000' : '#ffffff'
    const primaryImageInvert = isBrightColor(basicTheme['primary-color']) ? 'invert(0)' : 'invert(1)'
    const linkColorHover =
      basicTheme['link-color-hover'] ??
      shadeHexColor(basicTheme['link-color'], isBrightColor(basicTheme['link-color']) ? -0.2 : 0.2)
    const titleColorHover =
      basicTheme['title-color-hover'] ??
      shadeHexColor(basicTheme['title-color'], isBrightColor(basicTheme['title-color']) ? -0.2 : 0.25)

    const playerPrimaryColor = basicTheme['player-primary-color']

    return {
      ...basicTheme,
      ['primary-background-text-color']: primaryTextColor,
      ['primary-background-image-invert-filter']: primaryImageInvert,
      ['link-color-hover']: linkColorHover,
      ['title-color-hover']: titleColorHover,
      ['player-primary-color']: playerPrimaryColor,
    }
  }, [defaultThemeColors, themeColors])

  const theme: ThemeProps = useMemo(() => {
    const loadingImageUrl = logoWhiteUrl || faviconUrl || logoUrl

    return {
      ...basicTheme,
      'blur-image-background-url': loadingImageUrl,
      'logo-url': logoUrl ?? null,
      'logo-white-url': logoWhiteUrl ?? null,
      'favicon-url': faviconUrl ?? null,
      ['background-image-url']: settings['style.background_image_url']
        ? `url(${settings['style.background_image_url']})`
        : 'initial',
    }
  }, [basicTheme, faviconUrl, logoUrl, logoWhiteUrl, settings])

  const antdTheme: ThemeConfig = useMemo(() => {
    const boxShadowColor = basicTheme['box-shadow-color']
    const boxShadow = boxShadowColor
      ? `0 1px 2px 0 ${boxShadowColor}, 0 1px 6px -1px ${boxShadowColor}, 0 2px 4px 0 ${boxShadowColor}`
      : undefined

    return {
      token: {
        colorPrimary: basicTheme['primary-color'],
        colorSuccess: basicTheme['success-color'],
        colorError: basicTheme['error-color'],
        colorWarning: basicTheme['warning-color'],
        colorInfo: basicTheme['info-color'],
        colorLink: basicTheme['link-color'],
        colorLinkHover: basicTheme['link-color-hover'],
        colorTextBase: basicTheme['default-text-color'],
        colorTextLightSolid: basicTheme['default-text-color-light-solid'],
        colorBgBase: basicTheme['component-background-color'],
        boxShadow,
        boxShadowSecondary: boxShadow,
        colorBorder: basicTheme['border-color'],
      },
      components: {
        Button: {
          colorPrimary: basicTheme['primary-color'],
          colorPrimaryText: basicTheme['primary-background-text-color'],
          algorithm: true,
        },
      },
    }
  }, [basicTheme])

  const primaryColor = useMemo(() => theme['primary-color'] as string, [theme])

  useEffect(() => {
    const onWindowResize = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
      document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`)

      setTimeout(() => {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
        document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`)
      })
    }
    window.addEventListener('resize', onWindowResize, { passive: true })
    window.addEventListener('orientationchange', onWindowResize, { passive: true })
    onWindowResize()

    return () => {
      window.removeEventListener('resize', onWindowResize)
      window.removeEventListener('orientationchange', onWindowResize)
    }
  }, [])

  const { isPreviewBarVisible } = useContext(PreviewContext)
  const [headerHeight, setHeaderHeight] = useState(defaultValues.headerHeight)
  const headerBlockHeight = useMemo(() => {
    let headerBlockHeight = 0
    if (isHeaderVisible) headerBlockHeight += headerHeight
    if (isPreviewBarVisible) headerBlockHeight += 28

    return headerBlockHeight
  }, [isHeaderVisible, isPreviewBarVisible, headerHeight])

  useEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${headerBlockHeight}px`)
    document.documentElement.style.setProperty('--fixed-area-height', `${headerBlockHeight}px`)
  }, [headerBlockHeight])

  const [footerHeight, setFooterHeight] = useState(defaultValues.footerHeight)
  useEffect(() => {
    document.documentElement.style.setProperty('--footer-height', `${footerHeight}px`)
  }, [footerHeight])

  return (
    <CustomThemeContext.Provider
      value={{
        theme,
        headerHeight,
        headerBlockHeight,
        isHeaderVisible,
        isHeaderTransparent,
        setHeaderHeight,
        setIsHeaderVisible,
        setIsHeaderTransparent,
        footerHeight,
        setFooterHeight,
      }}
    >
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <NextProgress color={primaryColor} startPosition={0.3} stopDelayMs={200} height={3} />
          {children}
        </ConfigProvider>
      </ThemeProvider>
    </CustomThemeContext.Provider>
  )
}
