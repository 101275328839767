import { MemberRoles, MemberRoleType } from '@havppen/types/src/member'
import { ModuleProps } from 'src/types/common'

export const hasRole = (currentRole: MemberRoleType, targetRole: MemberRoleType) =>
  MemberRoles.indexOf(currentRole) <= MemberRoles.indexOf(targetRole)

export const isRoleEnabled = (
  currentMemberRole: MemberRoleType | string,
  allowedUserRole: MemberRoleType | undefined,
) => (allowedUserRole && hasRole(currentMemberRole as MemberRoleType, allowedUserRole)) || allowedUserRole === undefined

export const isModuleEnabled = (isModule = false, moduleKey: string, enabledModules: ModuleProps) =>
  isModule === false || enabledModules[moduleKey]

export const isCurrentRoleEnabled = (currentRole: MemberRoleType, targetRole?: MemberRoleType) => {
  return targetRole ? MemberRoles.indexOf(currentRole) <= MemberRoles.indexOf(targetRole) : true
}
