import { gql } from '@apollo/client'
import types from '@havppen/gql/types'
import * as R from 'ramda'
import { ApplicationBriefProps, ApplicationProps, Module, Nav, NavType } from 'src/types/app'
import { createTranslationIntl } from 'src/utils/i18n'
import { fetchAppIdByHost, getAppIdByDefaultHost } from './appId'

const defaultCurrency = 'TWD'

const formatAppSettings = R.reduce<{ key: string; value: string }, Record<string, string>>(
  (dict, { key, value }) => ({ ...dict, [key]: value }),
  {},
)
const formatEnabledModules = R.reduce<{ module_id: string | null }, Record<string, boolean>>(
  (dict, { module_id }) => ({ ...dict, [module_id as Module]: true }),
  {},
)
const formatThemeColors = R.pipe(
  R.filter<{ key: string; value: string }>(el => el.key.includes('style.@')),
  R.reduce<{ key: string; value: string }, Record<string, string>>(
    (dict, { key, value }) => ({ ...dict, [key.split('@')[1]]: value }),
    {},
  ),
)

export const getAppDomainNames = (
  appId: string,
  params: {
    frontDomain?: string
    backDomain?: string
  },
) => {
  const { frontDomain, backDomain } = params
  const defaultFrontDomain = `${appId}.${process.env.NEXT_PUBLIC_DEFAULT_APP_ROOT_DOMAIN}`
  const defaultBackDomain = `${appId}.${process.env.NEXT_PUBLIC_ADMIN_ROOT_DOMAIN}`

  return {
    frontDomain: frontDomain ?? defaultFrontDomain,
    backDomain: backDomain ?? defaultBackDomain,
  }
}

export const fetchAppSettings = async (appId?: string | null) => {
  if (!appId) return {} as Record<string, string>

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/app/${appId}`, {
    next: {
      revalidate: false,
      tags: [`app:${appId}`, `app_setting:${appId}`],
    },
  })
  const data: types.GET_APP | undefined = await response.json()
  const settings = formatAppSettings(data?.app_by_pk?.app_settings ?? [])

  return settings
}

export const fetchAppBriefByAppId = async (appId?: string | null) => {
  if (!appId) return null

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/app/${appId}`, {
    next: {
      revalidate: false,
      tags: [`app:${appId}`, `app_domain:${appId}`, `app_module:${appId}`, `app_nav:${appId}`, `app_setting:${appId}`],
    },
  })
  const data: types.GET_APP | undefined = await response.json()
  if (!data?.app_by_pk) return null

  const app_data = data.app_by_pk
  const { frontDomain, backDomain } = getAppDomainNames(app_data.id, {
    frontDomain: app_data.front_domain?.[0]?.domain,
    backDomain: app_data.back_domain?.[0]?.domain,
  })

  const settings = formatAppSettings(app_data.app_settings)
  const enabledModules = formatEnabledModules(app_data.app_modules)

  const app: ApplicationBriefProps = {
    id: app_data.id,
    name: app_data.name,
    description: app_data.description,
    logoUrl: settings['style.logo_url'] ?? null,
    logoWhiteUrl: settings['style.logo_white_url'] ?? null,
    faviconUrl: settings['style.favicon_url'] ?? null,
    defaultAvatarUrl: settings['style.default_avatar_url'] ?? null,
    defaultLocale: settings['app.default-language'] ?? null,
    adminEmail: app_data.admin_email,
    trialExpiredAt: app_data.trial_expired_at,
    paymentType: app_data.payment_type ?? 'custom',
    siteType: app_data.site_type ?? 'general',
    timezone: app_data.timezone ?? 'Asia/Taipei',
    frontDomain,
    backDomain,
    enabledModules,
    settings,
  }

  return app
}

type sub_nav_type = types.APP_NAV_FRAGMENT & {
  sub_navs?: sub_nav_type[] | null
}
const formatSubNavs = (sub_navs: sub_nav_type[] | null | undefined) => {
  if (!sub_navs) return null

  const subNavs: Nav[] = sub_navs.map(({ icon_url, sub_navs, ...subNav }) => {
    const subNavs = formatSubNavs(sub_navs)

    return {
      ...subNav,
      type: subNav.type as NavType,
      iconUrl: icon_url,
      subNavs,
    }
  })
  subNavs?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))

  return subNavs
}

export const fetchAppByAppId = async (appId?: string | null) => {
  if (!appId) return null

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/app/${appId}`, {
    next: {
      revalidate: false,
      tags: [`app:${appId}`, `app_domain:${appId}`, `app_module:${appId}`, `app_nav:${appId}`, `app_setting:${appId}`],
    },
  })
  const data: types.GET_APP | undefined = await response.json()
  if (!data?.app_by_pk) return null

  const app_data = data.app_by_pk
  const { frontDomain, backDomain } = getAppDomainNames(app_data.id, {
    frontDomain: app_data.front_domain?.[0]?.domain,
    backDomain: app_data.back_domain?.[0]?.domain,
  })

  const paymentType = app_data.payment_type ?? 'custom'
  const siteType = app_data.site_type ?? 'general'
  const settings = formatAppSettings(app_data.app_settings)
  const enabledModules = formatEnabledModules(app_data.app_modules)

  const intl = createTranslationIntl(settings['app.default-language'])
  const navs: Nav[] = (app_data.app_navs || []).map(({ icon_url, sub_navs, ...nav }) => {
    const subNavs = formatSubNavs(sub_navs)

    return {
      ...nav,
      type: nav.type as NavType,
      iconUrl: icon_url,
      subNavs,
    }
  })

  if (siteType === 'general') {
    navs.push({
      id: 'terms',
      label: intl.formatMessage({ id: 'page.footer.terms' }),
      href: '/terms',
      block: 'footer',
      position: 99,
    })
  }
  if (paymentType === 'agent') {
    navs.push({
      id: 'terms-of-transaction',
      label: intl.formatMessage({ id: 'page.footer.termsOfTransactions' }),
      href: '/terms/transaction',
      block: 'footer',
      position: 98,
    })
  }

  // if (enabledModules.nft) {
  //   navs.push({
  //     id: 'my-collection',
  //     label: intl.formatMessage({ id: 'page.header.myNFTCollection' }),
  //     href: '/my-collection',
  //     external: false,
  //     block: 'header',
  //     position: 99,
  //   })
  // }

  navs.sort((a, b) => (a.position || 0) - (b.position || 0))
  const app: ApplicationProps = {
    id: app_data.id,
    name: app_data.name,
    description: app_data.description,
    logoUrl: settings['style.logo_url'] ?? null,
    logoWhiteUrl: settings['style.logo_white_url'] ?? null,
    faviconUrl: settings['style.favicon_url'] ?? null,
    defaultAvatarUrl: settings['style.default_avatar_url'] ?? null,
    defaultLocale: settings['app.default-language'] ?? null,
    layoutType: settings['app.layout-type'] ?? 'default',
    isAppInitialized: settings['app.is-initialized'] === 'true',
    adminEmail: app_data.admin_email,
    trialExpiredAt: app_data.trial_expired_at,
    paymentType,
    siteType,
    timezone: app_data.timezone ?? 'Asia/Taipei',
    themeColors: formatThemeColors(app_data.app_settings),
    frontDomain,
    backDomain,
    defaultCurrency,
    navs,
    enabledModules,
    settings,
  }

  return app
}

export const fetchAppByHost = async (host?: string | null) => {
  let appId: string | null = null
  if (!appId) {
    appId = getAppIdByDefaultHost(host)
  }
  if (!appId) {
    appId = await fetchAppIdByHost(host)
  }
  if (!appId) {
    return null
  }

  return fetchAppByAppId(appId)
}

export const fetchAppFrontDomain = async (appId?: string | null) => {
  if (!appId) return null

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/front_domain/${appId}`, {
    next: {
      revalidate: false,
      tags: [`app_domain:${appId}`],
    },
  })
  const data: types.GET_APP_FRONT_DOMAIN | undefined = await response.json()
  const { frontDomain } = getAppDomainNames(appId, {
    frontDomain: data?.app_domain?.[0]?.domain,
  })

  return frontDomain
}

export const fetchAppBackDomain = async (appId?: string | null) => {
  if (!appId) return null

  const response = await fetch(`https://${process.env.NEXT_PUBLIC_HASURA_HOST}/api/rest/back_domain/${appId}`, {
    next: {
      revalidate: false,
      tags: [`app_domain:${appId}`],
    },
  })
  const data: types.GET_APP_BACK_DOMAIN | undefined = await response.json()
  const { backDomain } = getAppDomainNames(appId, {
    backDomain: data?.app_domain?.[0]?.domain,
  })

  return backDomain
}

export const GET_APP_FRONT_DOMAIN = gql`
  query GET_APP_FRONT_DOMAIN($appId: String!) {
    app_domain(where: { app_id: { _eq: $appId }, type: { _eq: "app" }, default: { _eq: true } }, limit: 1) {
      domain
    }
  }
`

export const GET_APP_BACK_DOMAIN = gql`
  query GET_APP_BACK_DOMAIN($appId: String!) {
    app_domain(where: { app_id: { _eq: $appId }, type: { _eq: "admin" }, default: { _eq: true } }, limit: 1) {
      domain
    }
  }
`
