'use client'

import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { createGlobalStyle } from 'styled-components'

export const BREAK_POINT_SM = 576
export const BREAK_POINT_MD = 768
export const BREAK_POINT = 992
export const BREAK_POINT_XL = 1200

export const BREAK_POINTS: { [key in ResponsiveMediaSizeType]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

export const ResponsiveStyle = createGlobalStyle`
  .responsive {
    &-portrait, &-landscape {
      display: none !important;
    }
    &-portrait {
      @media (orientation: portrait) {
        display: block !important;
      }
    }
    &-landscape {
      @media (orientation: landscape) {
        display: block !important;
      }
    }
  }
`

export type ResponsiveMediaSizeType = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
export type ResponsiveOrientationType = 'portrait' | 'landscape'

type ResponsiveMediaProps = React.PropsWithChildren<{
  lessThan?: ResponsiveMediaSizeType
  greaterThanOrEqual?: ResponsiveMediaSizeType
  orientation?: ResponsiveOrientationType
  className?: string
}>

const ResponsiveMedia: React.FC<ResponsiveMediaProps> = ({
  className: defaultClassName = '',
  lessThan,
  greaterThanOrEqual,
  orientation,
  children,
}) => (
  <div
    className={clsx(
      defaultClassName,
      lessThan && `d-block d-${lessThan}-none`,
      greaterThanOrEqual && `d-none d-${greaterThanOrEqual}-block`,
      orientation && `responsive-${orientation}`,
    )}
  >
    {children}
  </div>
)

export const DefaultResponsiveMedia: React.FC<ResponsiveMediaProps> = ({
  className,
  lessThan,
  greaterThanOrEqual,
  orientation,
  children,
}) => {
  const mediaQueryString = useMemo(() => {
    const mediaQueries: string[] = []
    if (lessThan) mediaQueries.push(`(max-width: ${BREAK_POINTS[lessThan] - 1}px)`)
    if (greaterThanOrEqual) mediaQueries.push(`(min-width: ${BREAK_POINTS[greaterThanOrEqual]}px)`)
    if (orientation) mediaQueries.push(`(orientation: ${orientation})`)

    return mediaQueries.join(' and ')
  }, [greaterThanOrEqual, lessThan, orientation])

  const [isVisible, setIsVisible] = useState(true)
  useEffect(() => {
    const onWindowResize = () => {
      const { matches } = window.matchMedia(mediaQueryString)
      setIsVisible(matches)
    }

    onWindowResize()
    window.addEventListener('resize', onWindowResize, { passive: true })
    return () => window.removeEventListener('resize', onWindowResize)
  }, [mediaQueryString])

  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return isVisible || !isLoaded ? (
    <ResponsiveMedia
      className={className}
      lessThan={lessThan}
      greaterThanOrEqual={greaterThanOrEqual}
      orientation={orientation}
    >
      {children}
    </ResponsiveMedia>
  ) : (
    <></>
  )
}

const mediaQueries = {
  mediaQueryMobile: `(max-width: ${BREAK_POINT - 1}px)`,
  mediaQueryDesktop: `(min-width: ${BREAK_POINT}px)`,
  mediaQueryPortrait: '(orientation: portrait)',
  mediaQueryMobileLandScape: `(max-width: ${BREAK_POINT - 1}px) and (max-height: 300px) and (orientation: landscape)`,
}

const defaultResponsive = {
  isMobile: true,
  isDesktop: true,
  isPortrait: true,
  isMobilePortrait: true,
  isMobileLandscape: true,
  isMobileLandscapeToolbar: true,
}

export const ResponsiveContext = React.createContext<{
  isMobile: boolean
  isDesktop: boolean
  isPortrait: boolean
  isMobilePortrait: boolean
  isMobileLandscape: boolean
  isMobileLandscapeToolbar: boolean
}>({
  isMobile: defaultResponsive.isMobile,
  isDesktop: defaultResponsive.isDesktop,
  isPortrait: defaultResponsive.isMobilePortrait,
  isMobilePortrait: defaultResponsive.isMobilePortrait,
  isMobileLandscape: defaultResponsive.isMobileLandscape,
  isMobileLandscapeToolbar: defaultResponsive.isMobileLandscapeToolbar,
})

export const ResponsiveProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(defaultResponsive.isMobile)
  const [isDesktop, setIsDesktop] = useState(defaultResponsive.isDesktop)
  const [isPortrait, setIsPortrait] = useState(defaultResponsive.isPortrait)
  const [isMobileLandscapeToolbar, setIsMobileLandscapeToolbar] = useState(defaultResponsive.isMobileLandscapeToolbar)
  const isMobilePortrait = isMobile && isPortrait
  const isMobileLandscape = isMobile && !isPortrait

  useEffect(() => {
    const onWindowResize = () => {
      const { matches: matchesMobile } = window.matchMedia(mediaQueries.mediaQueryMobile)
      const { matches: matchesDesktop } = window.matchMedia(mediaQueries.mediaQueryDesktop)
      const { matches: matchesPortrait } = window.matchMedia(mediaQueries.mediaQueryPortrait)
      const { matches: matchesMobileLandscapeToolbar } = window.matchMedia(mediaQueries.mediaQueryMobileLandScape)

      setIsMobile(matchesMobile)
      setIsDesktop(matchesDesktop)
      setIsPortrait(matchesPortrait)
      setIsMobileLandscapeToolbar(matchesMobileLandscapeToolbar)
    }

    onWindowResize()
    window.addEventListener('resize', onWindowResize, { passive: true })
    return () => window.removeEventListener('resize', onWindowResize)
  }, [])
  return (
    <ResponsiveContext.Provider
      value={{ isMobile, isDesktop, isPortrait, isMobilePortrait, isMobileLandscape, isMobileLandscapeToolbar }}
    >
      {children}
    </ResponsiveContext.Provider>
  )
}

type ResponsiveBlockProps = React.PropsWithChildren & {
  className?: string
}
export const Responsive = {
  Default: ({ className, children }: ResponsiveBlockProps) => (
    <ResponsiveMedia className={className} lessThan="lg">
      {children}
    </ResponsiveMedia>
  ),
  Desktop: ({ className, children }: ResponsiveBlockProps) => (
    <ResponsiveMedia className={className} greaterThanOrEqual="lg">
      {children}
    </ResponsiveMedia>
  ),
  MobilePortrait: ({ className, children }: ResponsiveBlockProps) => (
    <ResponsiveMedia className={className} lessThan="lg" orientation="portrait">
      {children}
    </ResponsiveMedia>
  ),
  Media: ResponsiveMedia,
}
