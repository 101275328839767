import { gql } from '@apollo/client'

export const MEMBER_PUBLIC_COLUMNS = gql`
  fragment MEMBER_PUBLIC_COLUMNS on member_public {
    id
    name
    display_name
    username
    avatar_url
    is_private
  }
`

export const MEMBER_PRIVATE_COLUMNS = gql`
  fragment MEMBER_PRIVATE_COLUMNS on member {
    id
    name
    display_name
    username
    role
    avatar_url
    email
    phone
    sex
    birthday
    confirmed_email_at
    confirmed_phone_at
    unconfirmed_email
    unconfirmed_phone
    app_id
    login_at
    revoked_at
  }
`

export const MEMBER_AUTH_COLUMNS = gql`
  fragment MEMBER_AUTH_COLUMNS on member {
    id
    name
    display_name
    username
    role
    password_hash
    avatar_url
    email
    phone
    sex
    birthday
    unconfirmed_email
    unconfirmed_phone
    confirmed_email_at
    confirmed_phone_at
    app_id
    login_at
    revoked_at
  }
`

export const MEMBER_JWT_COLUMNS = gql`
  fragment MEMBER_JWT_COLUMNS on member {
    id
    username
    name
    display_name
    email
    role
    avatar_url
    confirmed_email_at
    app_id
    login_at
  }
`

export const MEMBER_COLUMNS = gql`
  fragment MEMBER_COLUMNS on member {
    id
    name
    display_name
    abstract
    username
    avatar_url
    title
  }
`

export const GET_MEMBER_METADATA = gql`
  query GET_MEMBER_METADATA($memberId: String!) {
    member_by_pk(id: $memberId) {
      id
      username
      name
      display_name
      email
      app_id
      role
      metadata
    }
  }
`

export const INSERT_MEMBER = gql`
  mutation INSERT_MEMBER($object: member_insert_input!) {
    insert_member_one(object: $object) {
      id
    }
  }
`

export const GET_MEMBER_REGISTER_PENDING_INVITATIONS = gql`
  query GET_MEMBER_REGISTER_PENDING_INVITATIONS {
    member_register_invitation(where: { registered_at: { _is_null: true } }) {
      id
      email
      member_id
      role
      created_at
      updated_at
      registered_at
    }
  }
`

export const GET_MEMBER_REGISTER_INVITATIONS = gql`
  query GET_MEMBER_REGISTER_INVITATIONS($condition: member_register_invitation_bool_exp!, $limit: Int, $offset: Int) {
    member_register_invitation(where: $condition, limit: $limit, offset: $offset) {
      id
      email
      member_id
      role
      created_at
      updated_at
      registered_at
    }
  }
`

export const GET_MEMBER_REGISTER_INVITATION = gql`
  query GET_MEMBER_REGISTER_INVITATION($invitationId: uuid!) {
    member_register_invitation_by_pk(id: $invitationId) {
      id
      email
      member_id
      role
      created_at
      updated_at
      registered_at
    }
  }
`

export const UPDATE_MEMBER_REGISTER_INVITATION = gql`
  mutation UPDATE_MEMBER_REGISTER_INVITATION(
    $memberRegisterInvitationId: uuid!
    $_set: member_register_invitation_set_input!
  ) {
    update_member_register_invitation_by_pk(pk_columns: { id: $memberRegisterInvitationId }, _set: $_set) {
      id
    }
  }
`

export const GET_MEMBER_BY_ID = gql`
  query GET_MEMBER_BY_ID($memberId: String!) {
    member_by_pk(id: $memberId) {
      id
      name
      display_name
      email
      unconfirmed_email
      unconfirmed_phone
      username
      title
      avatar_url
      role
      sex
      birthday
      phone
      abstract
      metadata
      created_at
      login_at
      confirmed_email_at
      confirmed_phone_at
      profile_data
      is_private
    }
  }
`

export const GET_MEMBER_NOTIFICATION_SETTING = gql`
  query GET_MEMBER_NOTIFICATION_SETTING($memberId: String!, $notificationKey: String!) {
    member_by_pk(id: $memberId) {
      notification_settings(path: $notificationKey)
    }
  }
`

export const GET_MEMBERS_NOTIFICATION_SETTING = gql`
  query GET_MEMBERS_NOTIFICATION_SETTING($memberIds: [String!]!, $notificationKey: String!) {
    member(where: { id: { _in: $memberIds } }) {
      id
      notification_settings(path: $notificationKey)
    }
  }
`

export const GET_MEMBER_NOTIFICATION_SETTINGS = gql`
  query GET_MEMBER_NOTIFICATION_SETTINGS($memberId: String!) {
    member_by_pk(id: $memberId) {
      id
      notification_settings
    }
  }
`

export const UPDATE_MEMBER_NOTIFICATION_SETTINGS = gql`
  mutation UPDATE_MEMBER_NOTIFICATION_SETTINGS($memberId: String!, $append: member_append_input!) {
    update_member_by_pk(pk_columns: { id: $memberId }, _append: $append) {
      id
    }
  }
`

export const UPDATE_MEMBER_BY_ID = gql`
  mutation UPDATE_MEMBER_BY_ID($memberId: String!, $updated: member_set_input, $append: member_append_input) {
    update_member_by_pk(pk_columns: { id: $memberId }, _set: $updated, _append: $append) {
      ...MEMBER_AUTH_COLUMNS
    }
  }
  ${MEMBER_AUTH_COLUMNS}
`

export const INSERT_MEMBER_LOGIN = gql`
  mutation INSERT_MEMBER_LOGIN(
    $memberId: String!
    $appId: String!
    $loginStrategy: String!
    $success: Boolean
    $ip: String
    $userAgent: String
    $referrer: String
    $createdAt: timestamptz
  ) {
    insert_member_login_activity_one(
      object: {
        app_id: $appId
        member_id: $memberId
        login_strategy: $loginStrategy
        success: $success
        ip: $ip
        user_agent: $userAgent
        referrer: $referrer
        created_at: $createdAt
      }
    ) {
      id
    }
    update_member_by_pk(pk_columns: { id: $memberId }, _set: { login_at: $createdAt }) {
      id
    }
  }
`
