import enUS from 'antd/locale/en_US'
import zhTW from 'antd/locale/zh_TW'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-tw'

export const DEFAULT_LOCAL = 'zh-TW'
export const supportedLanguages = ['zh-TW', 'en-US']
export const antdLangMapping = {
  'zh-TW': zhTW,
  'en-US': enUS,
}
export const translationMessageMapping: { [lang: string]: any } = {
  'zh-TW': require('@havppen/translation/locales/zh-tw.json'),
  'en-US': require('@havppen/translation/locales/en-us.json'),
}
