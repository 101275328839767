import { SocialProfileProps } from 'src/types/shared'
import BroadcastChannelClient from './broadcast.channel.client'

export const AUTH_CHANNEL = 'AUTH_CHANNEL'
export type AuthChannelType = 'authToken' | 'socialLogin' | 'logout'

class AuthChannelClient {
  client: BroadcastChannelClient

  constructor() {
    this.client = new BroadcastChannelClient('AUTH_CHANNEL')
  }

  public initialize = async (initialEvents = true) => {
    if (initialEvents) {
      this.client.onMessage = event => {
        const data = event.data.data
        const type = event.data.type as AuthChannelType

        switch (type) {
          case 'authToken': {
            const authToken = data.authToken
            this.onAuthToken(authToken)
            break
          }

          case 'socialLogin': {
            const authToken = data.authToken
            const profile = data.profile
            if (authToken) {
              this.onAuthToken(authToken)
            }
            if (profile) {
              this.onSocialProfile(profile)
            }
            break
          }
          case 'logout': {
            this.onLogout()
          }
        }
      }
    }

    await this.client.initialize()
  }

  onAuthToken = (authToken: string) => {}
  onSocialProfile = (socialProfile: SocialProfileProps) => {}
  onLogout = () => {}

  postMessage = (type: AuthChannelType, data: any) => {
    this.client.postMessage(type, data)
  }
}

export default AuthChannelClient
