'use client'

import dynamic from 'next/dynamic'
import React from 'react'
import SEOWrapper from 'src/components/global/SEOWrapper'
import { ApiProvider } from 'src/contexts/ApiContext'
import { AppProvider } from 'src/contexts/AppContext'
import { AuthProvider } from 'src/contexts/AuthContext'
import { CartProvider } from 'src/contexts/CartContext'
import { CustomThemeProvider } from 'src/contexts/CustomThemeContext'
import { LanguageProvider } from 'src/contexts/LanguageContext'
import { PageProps } from 'src/types/shared'
import useSWR from 'swr'
import { AuthModalProvider } from './components/auth/AuthModalContext'
import ThirdPartyLibrary from './components/common/ThirdPartyLibrary'
import VersionCheckerBlock from './components/common/VersionCheckerBlock'
import DiscountCheck from './components/global/DiscountCheck'
import GlobalStyleElement from './components/global/GlobalStyleElement'
import RedirectCheck from './components/global/RedirectCheck'
import { PreviewProvider } from './contexts/PreviewContext'
import { ResponsiveProvider, ResponsiveStyle } from './contexts/ResponsiveContext'
import { TrackingProvider } from './contexts/TrackingContext'

const PageTracking = dynamic(() => import('./components/common/PageTracking'), { ssr: false })

const Application: React.FC<React.PropsWithChildren<{ pageProps: PageProps }>> = ({
  pageProps: { initialApolloState, trpcState, isPreview, isPreviewPurchased, app },
  children,
}) => {
  const { data: appId } = useSWR<string | undefined>('appId', {
    revalidateIfStale: false,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    refreshInterval: 0,
  })

  return (
    <ApiProvider appId={appId} initialState={initialApolloState} trpcState={trpcState}>
      <AuthProvider appId={appId}>
        <PreviewProvider isPreview={isPreview} isPreviewPurchased={isPreviewPurchased}>
          <AppProvider appId={appId} defaultAppProps={app}>
            <CustomThemeProvider defaultThemeColors={app?.themeColors}>
              <ResponsiveProvider>
                <LanguageProvider defaultLocale={app?.defaultLocale ?? undefined}>
                  <TrackingProvider>
                    <CartProvider>
                      <AuthModalProvider>
                        <GlobalStyleElement />
                        <ResponsiveStyle />
                        <SEOWrapper />
                        <ThirdPartyLibrary />
                        <RedirectCheck />
                        <DiscountCheck />
                        <PageTracking />
                        <VersionCheckerBlock />

                        {children}
                      </AuthModalProvider>
                    </CartProvider>
                  </TrackingProvider>
                </LanguageProvider>
              </ResponsiveProvider>
            </CustomThemeProvider>
          </AppProvider>
        </PreviewProvider>
      </AuthProvider>
    </ApiProvider>
  )
}

export default Application
