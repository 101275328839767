'use client'

import { UserOutlined } from '@ant-design/icons'
import { ShippingMethodProps } from '@havppen/types/src/orderRecord'
import { ShippingCvsOptionsProps } from '@havppen/types/src/shipping'
import { localStore } from '@havppen/utils/src/storageFactory'
import { Button, Checkbox, Form, FormInstance, Input, Radio, Space, Typography } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'src/contexts/AuthContext'
import { useMember } from 'src/hooks/member.client'
import { useCVSStoreSelect } from 'src/hooks/shipping'

export const SHIPPING_LOCAL_STORAGE_KEY = 'shippings'

const CheckoutShippingForm: React.FC<{
  form: FormInstance
  formName: NamePath
  groupBuyShippingCount?: number
  showShippingOptions?: boolean
  shippingMethods?: ShippingMethodProps[]
}> = ({ form, formName, groupBuyShippingCount = 1, showShippingOptions = false, shippingMethods = [] }) => {
  return (
    <Form form={form} layout="horizontal" colon={false}>
      {[...Array(groupBuyShippingCount)].map((_, index) => (
        <ShippingForm
          key={index}
          form={form}
          formName={formName}
          isGroupBuying={groupBuyShippingCount > 1}
          formItemIndex={index}
          showShippingOptions={showShippingOptions}
          shippingMethods={shippingMethods}
        />
      ))}
    </Form>
  )
}

const ShippingForm: React.FC<{
  form: FormInstance
  formName: NamePath
  formItemIndex: number
  isGroupBuying?: boolean
  showShippingOptions?: boolean
  shippingMethods?: ShippingMethodProps[]
}> = ({
  form,
  formName: defaultFormName,
  isGroupBuying,
  formItemIndex,
  showShippingOptions = false,
  shippingMethods = [],
}) => {
  const { currentMemberId } = useAuth()
  const { member } = useMember(currentMemberId)

  const formName = useMemo(() => [...defaultFormName, formItemIndex], [defaultFormName, formItemIndex])
  const showShippingFormItems = showShippingOptions && shippingMethods.length > 0
  const sameAsInvoiceInfo = Form.useWatch([...formName, 'sameAsInvoiceInfo'], form) as boolean | undefined
  const currentShippingMethod = Form.useWatch([...formName, 'method'], form) as string
  const isShippingAddressRequired = useMemo(
    () => ['home_delivery', 'mail'].includes(currentShippingMethod),
    [currentShippingMethod],
  )

  useEffect(() => {
    if (formItemIndex === 0) {
      const shippingData = form.getFieldValue(formName)
      const cachedShipping = JSON.parse(localStore.getItem(SHIPPING_LOCAL_STORAGE_KEY) || '{}')

      if (shippingMethods.length > 0) {
        form.setFieldValue(formName, {
          method: shippingMethods[0].key,
          ...member?.metadata?.shipping,
          ...cachedShipping,
          ...shippingData,
        })
      }
    }
  }, [form, formItemIndex, shippingMethods, member, formName])

  const formCvsOptions = Form.useWatch([...formName, 'cvsOptions'], form) as ShippingCvsOptionsProps | undefined
  const onReceiveCVSStoreData = useCallback(
    ({ cvsType, storeId, storeName, storeAddress, shippingIndex }: ShippingCvsOptionsProps) => {
      const formName = [...defaultFormName, parseInt(shippingIndex)]
      form.setFields([
        { name: [...formName, 'method'], value: cvsType },
        {
          name: [...formName, 'cvsOptions'],
          value: {
            cvsType,
            storeId,
            storeName,
            storeAddress,
          },
        },
      ])
    },
    [form, defaultFormName],
  )
  const { onStoreSelect } = useCVSStoreSelect(currentShippingMethod, {
    onReceiveCVSStoreData,
    shippingIndex: formItemIndex,
  })

  return (
    <div className={isGroupBuying ? 'mb-5' : ''}>
      {isGroupBuying && formItemIndex > 0 && (
        <div className="mb-3">
          <Typography.Text strong>
            <UserOutlined />
            <span className="ml-1">同行者 {formItemIndex}</span>
          </Typography.Text>
        </div>
      )}

      {showShippingFormItems && (
        <Form.Item name={[...formName, 'method']} rules={[{ required: true, message: '請選擇寄送方式' }]}>
          <Radio.Group>
            <Space direction="vertical">
              {shippingMethods.map(shippingMethod => {
                const isCVS = shippingMethod.key.includes('cvs_') && currentShippingMethod === shippingMethod.key

                return (
                  <Radio key={shippingMethod.key} value={shippingMethod.key}>
                    <span>
                      <FormattedMessage
                        id={`shipping.method.${shippingMethod.key}`}
                        defaultMessage={shippingMethod.key}
                      />
                    </span>
                    <span className="ml-2">$ {shippingMethod.price}</span>

                    {isCVS && (
                      <>
                        <Button className="ml-3" type="primary" shape="round" onClick={onStoreSelect}>
                          {formCvsOptions?.storeName ? '重新選擇門市' : '請選擇門市'}
                        </Button>

                        <span className="ml-2">{formCvsOptions?.storeName}</span>
                      </>
                    )}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item name={[...formName, 'sameAsInvoiceInfo']} valuePropName="checked" className="mb-2">
        <Checkbox>同購買資訊</Checkbox>
      </Form.Item>

      <Form.Item name={[...formName, 'cvsOptions']} hidden>
        <Input />
      </Form.Item>

      {!sameAsInvoiceInfo && (
        <>
          <Form.Item
            name={[...formName, 'name']}
            label="收件人姓名"
            rules={[{ required: true, message: '請輸入姓名' }]}
            className="mb-3"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={[...formName, 'phone']}
            label="收件人手機"
            rules={[
              { required: true, message: '請輸入聯絡手機號碼' },
              { max: 10, message: '手機號碼格式錯誤' },
              { min: 10, message: '手機號碼格式錯誤' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={[...formName, 'address']}
            label="收件人地址"
            rules={[{ required: isShippingAddressRequired, message: '請輸入地址' }]}
            hidden={!isShippingAddressRequired}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </div>
  )
}

export default CheckoutShippingForm
