import { gql } from '@apollo/client'

export const MEMBER_BRIEF_COLUMNS = gql`
  fragment MEMBER_BRIEF_COLUMNS on member {
    id
    name
    display_name
    abstract
    username
    avatar_url
    title
    app_id
    role
    email
    confirmed_email_at
  }
`

export const GET_REFERRER_MEMBER = gql`
  query GET_REFERRER_MEMBER($condition: member_bool_exp!) {
    member(where: $condition) {
      id
      name
      display_name
    }
  }
`

export const MEMBER_PUBLIC_PROFILE_FRAGMENT = gql`
  fragment MEMBER_PUBLIC_PROFILE_FRAGMENT on member_public {
    id
    name
    username
    display_name
    title
    avatar_url
    role
    abstract
    age_year
    profile_data @include(if: $isDetailShown)
    is_private
    member_followers_aggregate @include(if: $isDetailShown) {
      aggregate {
        count
      }
    }
    course_possessions_aggregate @include(if: $isDetailShown) {
      aggregate {
        count
      }
    }
    product_owners_aggregate(
      where: {
        product_type: { _nin: ["Merchandise", "MerchandiseOption", "ActivitySession", "PackagePlan"] }
        product: {
          deleted_at: { _is_null: true }
          online_at: { _lte: "now()" }
          _or: [{ offline_at: { _gte: "now()" } }, { offline_at: { _is_null: true } }]
        }
      }
    ) @include(if: $isDetailShown) {
      aggregate {
        count
      }
    }
    course_instructors_aggregate(
      where: { course: { status: { _in: ["coming_soon", "fundraising", "preorder", "published"] } } }
    ) @include(if: $isDetailShown) {
      aggregate {
        count
      }
    }
  }
`

export const GET_MEMBER_PUBLICS = gql`
  query GET_MEMBER_PUBLICS(
    $condition: member_public_bool_exp
    $limit: Int
    $offset: Int
    $isDetailShown: Boolean!
    $orderBy: [member_public_order_by!]
  ) {
    member_public(where: $condition, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...MEMBER_PUBLIC_PROFILE_FRAGMENT
    }
    member_public_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${MEMBER_PUBLIC_PROFILE_FRAGMENT}
`
