import { gql } from '@apollo/client'
import { PRODUCT_COLUMNS } from '@havppen/gql/src/product'

export const GET_PRODUCT_DISCOUNT = gql`
  query GET_PRODUCT_DISCOUNT($productDiscountId: uuid!) {
    product_discount_by_pk(id: $productDiscountId) {
      id
      name
      type
      target_unit
      target_amount
      start_at
      end_at
      base_limit_unit
      base_limit_amount
      product_id
      created_at
      metadata
    }
  }
`

export const GET_PACKAGE_PLAN_PRODUCTS = gql`
  query GET_PACKAGE_PLAN_PRODUCTS($packagePlanId: uuid!) {
    package_plan_product(where: { package_plan_id: { _eq: $packagePlanId } }) {
      product_id
      metadata
      product {
        ...PRODUCT_COLUMNS
        custom_profit_sharing_ratio
        product_owners {
          member_id
        }
      }
    }
  }
  ${PRODUCT_COLUMNS}
`

export const GET_PACKAGE_PLAN_PRODUCT_IDS = gql`
  query GET_PACKAGE_PLAN_PRODUCT_IDS($packagePlanId: uuid!) {
    package_plan_product(where: { package_plan_id: { _eq: $packagePlanId } }) {
      id
      product_id
    }
  }
`

export const GET_PRODUCT_BUNDLE_PRODUCTS = gql`
  query GET_PRODUCT_BUNDLE_PRODUCTS($productBundleId: uuid!) {
    product_bundle_product(where: { product_bundle_id: { _eq: $productBundleId } }) {
      product_id
      metadata
      product {
        ...PRODUCT_COLUMNS
        custom_profit_sharing_ratio
        product_owners {
          member_id
        }
      }
    }
  }
  ${PRODUCT_COLUMNS}
`

export const GET_PRODUCT_BUNDLE_PRODUCT_IDS = gql`
  query GET_PRODUCT_BUNDLE_PRODUCT_IDS($productBundleId: uuid!) {
    product_bundle_product(where: { product_bundle_id: { _eq: $productBundleId } }) {
      id
      product_id
    }
  }
`

export const GET_PRODUCT_POSSESSION = gql`
  query GET_PRODUCT_POSSESSION($condition: materialized_product_possession_bool_exp!) {
    materialized_product_possession(where: $condition, limit: 1) {
      quantity
    }
  }
`

export const GET_PRODUCT_POSSESSION_AGGREGATE_COUNT = gql`
  query GET_PRODUCT_POSSESSION_AGGREGATE_COUNT($condition: materialized_product_possession_bool_exp!) {
    materialized_product_possession_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
`

export const GET_PRODUCT_POSSESSION_COUNT = gql`
  query GET_PRODUCT_POSSESSION_COUNT($condition: product_possession_count_bool_exp!) {
    product_possession_count(where: $condition, limit: 1) {
      possession_count
    }
  }
`
