'use client'

import React from 'react'
import { useSignUpReferralCode } from 'src/hooks/auth'

const DiscountCheck: React.FC = () => {
  useSignUpReferralCode()

  return <></>
}

export default DiscountCheck
