import { gql } from '@apollo/client'
import { PRODUCT_COLUMNS } from '@havppen/gql/src/product'

export const GET_COUPONS = gql`
  query GET_COUPONS($condition: coupon_bool_exp) {
    coupon(where: $condition) {
      id
      title
      abstract
      app_id
      online_at
      offline_at
      scope
      discount_unit
      discount_amount
      base_limit_amount
      is_reusable
      status

      coupon_products {
        id
        product_id
        is_excluded
        product {
          ...PRODUCT_COLUMNS
        }
      }
    }
  }
  ${PRODUCT_COLUMNS}
`

export const GET_COUPON = gql`
  query GET_COUPON(
    $couponId: uuid!
    $couponProductCondition: coupon_product_bool_exp
    $couponCodeCondition: coupon_code_bool_exp
  ) {
    coupon_by_pk(id: $couponId) {
      id
      title
      abstract
      app_id
      member_id
      online_at
      offline_at
      scope
      is_reusable
      discount_unit
      discount_amount
      base_limit_amount
      custom_profit_sharing_amount
      custom_profit_sharing_unit
      is_app_owner
      is_package_or_bundle_included
      status

      coupon_products(where: $couponProductCondition) {
        id
        product_id
        is_excluded
        product {
          ...PRODUCT_COLUMNS
        }
      }

      coupon_codes(where: $couponCodeCondition, limit: 1) {
        id
        code
      }
    }
  }
  ${PRODUCT_COLUMNS}
`

export const GET_COUPON_CODE = gql`
  query GET_COUPON_CODE($condition: coupon_code_bool_exp) {
    coupon_code(where: $condition, limit: 1) {
      id
      code
      count
      coupon {
        id
        title
        abstract
        app_id
        online_at
        offline_at
        scope
        discount_unit
        discount_amount
        base_limit_amount
        status
      }
      coupon_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_COUPON_MEMBER_STATS = gql`
  query GET_COUPON_MEMBER_STATS($memberId: String!) {
    available_coupon_member_aggregate: coupon_member_aggregate(
      where: {
        is_used: { _eq: false }
        member_id: { _eq: $memberId }
        coupon_code: { coupon: { status: { _eq: "published" } } }
      }
    ) {
      aggregate {
        count
      }
    }
    used_coupon_member_aggregate: coupon_member_aggregate(
      where: { is_used: { _eq: true }, member_id: { _eq: $memberId } }
    ) {
      aggregate {
        count
      }
    }
    expired_coupon_member_aggregate: coupon_member_aggregate(
      where: { member_id: { _eq: $memberId }, coupon_code: { coupon: { status: { _eq: "offline" } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const INSERT_COUPON_MEMBER = gql`
  mutation INSERT_COUPON_MEMBER($object: coupon_member_insert_input!) {
    insert_coupon_member_one(
      object: $object
      on_conflict: {
        constraint: coupon_member_coupon_code_id_member_id_key
        update_columns: [member_id, coupon_code_id]
      }
    ) {
      id
    }
  }
`
