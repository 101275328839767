// organize-imports-ignore
import { AppProps, NextWebVitalsMetric } from 'next/app'
import Application from 'src/Application'
import ErrorBoundary from 'src/components/common/ErrorBoundary'
import SWRWrapper from 'src/components/common/SWRWrapper'
import { NextPageWithLayout, PageProps } from 'src/types/shared'

import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'antd/dist/reset.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'keen-slider/keen-slider.min.css'

interface AppWithErrorProps extends AppProps<PageProps> {
  Component: NextPageWithLayout
  err: any
}

const MyApp = ({ Component, pageProps, err }: AppWithErrorProps) => {
  const getLayout = Component.getLayout ?? (page => page)

  return (
    <ErrorBoundary>
      <SWRWrapper fallback={{ appId: pageProps.appId, app: pageProps.app }}>
        <Application pageProps={pageProps}>{getLayout(<Component {...pageProps} err={err} />)}</Application>
      </SWRWrapper>
    </ErrorBoundary>
  )
}

const sendMetric = (metric: NextWebVitalsMetric) => {
  const { id, name, label, value } = metric
  window.gtag?.('event', name, {
    event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  })
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (typeof window !== 'undefined') {
    if (!window.gtag) {
      if (!window.webVitals) {
        window.webVitals = []
      }
      window.webVitals.push(metric)
    } else {
      if (window.webVitals) {
        while (window.webVitals.length > 0) {
          const savedMetric = window.webVitals.shift()
          if (savedMetric) {
            sendMetric(savedMetric)
          }
        }
      }
      sendMetric(metric)
    }
  }
}

export default MyApp
