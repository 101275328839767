import { gql } from '@apollo/client'
import types from '../types'

export type ProductType = 'Article' | 'Course' | 'PackagePlan' | 'Activity' | 'Merchandise' | 'Appointment'
export type ProductProps = {
  id: string
  productType: ProductType
  productTarget: string
  path: string | null
  slug: string | null | undefined
  name: string | null | undefined
  type: string | null | undefined
  target: string | null | undefined
  memberId: string | null | undefined
  authorId: string | null | undefined
  listPrice: number
  onlineAt: Date | null | undefined
  featuredImageUrl: string | null | undefined
  fundraisingDueAt: Date | null | undefined
  isPhysical: boolean
  hasVariantOptions: boolean
}

export const mapProductFromGql: (product: types.PRODUCT_COLUMNS | null) => ProductProps | null = product => {
  return product
    ? {
        id: product.id as string,
        productType: product.type as ProductType,
        productTarget: product.target as string,
        path: product.path,
        name: product.name,
        slug: product.slug,
        type: product.type,
        target: product.target,
        memberId: product.member_id,
        authorId: product.author_id,
        listPrice: product.list_price,
        onlineAt: product.online_at ? new Date(product.online_at) : null,
        featuredImageUrl: product.featured_image_url,
        fundraisingDueAt: product.fundraising_due_at ? new Date(product.fundraising_due_at) : null,
        isPhysical: product.is_physical ?? false,
        hasVariantOptions: product.has_variant_options ?? false,
      }
    : null
}

export const PRODUCT_COLUMNS = gql`
  fragment PRODUCT_COLUMNS on product {
    id
    slug
    name
    type
    target
    list_price
    member_id
    author_id
    online_at
    featured_image_url
    fundraising_due_at
    is_physical
    has_variant_options
    path
  }
`

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($condition: product_bool_exp!) {
    product(where: $condition) {
      ...PRODUCT_COLUMNS
    }
  }
  ${PRODUCT_COLUMNS}
`

export const GET_PRODUCTS_WITH_POSSESSION = gql`
  query GET_PRODUCTS_WITH_POSSESSION($condition: product_bool_exp!) {
    product(where: $condition) {
      ...PRODUCT_COLUMNS

      product_possessions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${PRODUCT_COLUMNS}
`
