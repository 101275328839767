'use client'

import { Button, notification } from 'antd'
import React from 'react'
import { trpc } from 'src/helpers/trpc'

const currentVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'dev'

export const VersionCheckerBlock: React.FC = () => {
  const [api, contextHolder] = notification.useNotification()
  trpc.version.useQuery(undefined, {
    refetchOnReconnect: true,
    staleTime: 3600 * 3 * 1000,
    onSuccess: newVersion => {
      if (currentVersion !== newVersion) {
        api.info({
          message: '您有新版本可以更新',
          description: (
            <Button
              type="primary"
              onClick={() => {
                window.location.reload()
              }}
            >
              點此重新整理
            </Button>
          ),
          placement: 'bottomRight',
        })
      }
    },
  })

  return contextHolder
}

export default VersionCheckerBlock
