'use client'

import { useRouter, useSearchParams } from 'next/navigation'
import { useRouter as usePageRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import PreviewContext from 'src/contexts/PreviewContext'

const updateIsRedirecting = (isRedirecting: boolean) => {
  try {
    sessionStorage.setItem('isRedirecting', isRedirecting.toString())
  } catch {}
}

const RedirectCheck: React.FC = () => {
  const router = useRouter()
  const { settings } = useApp()
  const { isAuthenticating, isAuthenticated } = useAuth()
  const { isPreview } = useContext(PreviewContext)

  useEffect(() => {
    const isUnderMaintenance =
      !isAuthenticating &&
      !isAuthenticated &&
      settings['maintenance.is_under_maintenance'] &&
      process.env.NODE_ENV === 'production'
    if (isUnderMaintenance && !isPreview) {
      router.push('/maintenance')
    }
  }, [isAuthenticated, isAuthenticating, isPreview, router, settings])

  useEffect(() => {
    const ua = navigator.userAgent
    const isLineApp = ua.indexOf('Line') > -1
    const searchParams = new URLSearchParams(window.location.search)
    if (isLineApp) {
      searchParams.set('openExternalBrowser', '1')
      window.location.search = searchParams.toString()
    }
  }, [])

  const pageRouter = usePageRouter()
  const searchParams = useSearchParams()
  useEffect(() => {
    const backUrl = searchParams?.get('backUrl')
    const redirectToTargetUrl = async (backUrl: string) => {
      const uriObj = new URL(window.location.href)
      uriObj.searchParams.delete('backUrl')

      const backPath = `/back#backUrl=${backUrl}`
      updateIsRedirecting(true)
      try {
        await pageRouter.push(backPath, undefined, { shallow: true })
        await pageRouter.push(uriObj.href, undefined, { shallow: true })
      } catch (error) {
        console.error(error)
      }
      updateIsRedirecting(false)
    }

    if (backUrl) {
      redirectToTargetUrl(backUrl)
    }
  }, [pageRouter, searchParams])

  return null
}

export default RedirectCheck
