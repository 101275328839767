export const isBrightColor = (hexColor = '#ffffff') => {
  let hex: number[] | undefined

  // Check the format of the color, HEX or RGB?
  if (hexColor.match(/^rgb/)) {
    hex = hexColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)?.map(color => Number(color))
  } else {
    hex = hexColor
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      ?.map(x => parseInt(x, 16))
  }
  if (!hex) return true

  const [r, g, b] = hex
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp > 127.5
}

export const getTextColorByBackground = (hexColor: string) => {
  let hex: number[] | undefined

  // Check the format of the color, HEX or RGB?
  if (hexColor.match(/^rgb/)) {
    hex = hexColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)?.map(color => Number(color))
  } else {
    hex = hexColor
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      ?.map(x => parseInt(x, 16))
  }
  if (!hex) return '#ffffff'

  const [r, g, b] = hex
  const color = Math.round((r * 299 + g * 587 + b * 114) / 1000)
  return color > 140 ? '#000000' : '#ffffff'
}

export function shadeHexColor(color: string, percent: number) {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  )
}
