import { ApolloLink, createHttpLink, Operation, ServerError, ServerParseError, split } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { NIL as NIL_UUID } from 'uuid'

export type AuthLinkProps = {
  appId?: string | null
  authToken?: string | null
  adminSecret?: string | null
}

const retryErrorMessages = ['Failed to fetch', 'Load failed', 'Unexpected token < in JSON at position 0']

export const createApolloLink = (
  authOptions: AuthLinkProps,
  options?: {
    onInvalidJWTError?: (error: any) => void
    onCaptureGraphQLError?: (error: any, operation: Operation) => void
    onCaptureNetworkError?: (error: NetworkError | ServerParseError | ServerError) => void
  },
) => {
  const batchHttpLink = new BatchHttpLink({
    uri: `https://${process.env.NEXT_PUBLIC_HASURA_HOST}/v1/graphql`,
    headers: { batch: 'true' },
  })
  const httpLink = createHttpLink({ uri: `https://${process.env.NEXT_PUBLIC_HASURA_HOST}/v1/graphql` })
  const httpLinks = split(operation => operation.getContext().important === true, httpLink, batchHttpLink)
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: authOptions?.adminSecret
        ? { 'x-hasura-admin-secret': authOptions.adminSecret }
        : authOptions?.authToken
        ? { authorization: `Bearer ${authOptions?.authToken}` }
        : {
            'x-hasura-app-id': authOptions?.appId || '',
            'x-hasura-user-id': NIL_UUID,
            'x-hasura-role': 'anonymous',
          },
    })
    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(error => {
        console.error(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}, ${error.extensions?.code}`,
          error.extensions,
        )

        if (error.extensions && error.extensions.code === 'invalid-jwt') {
          options?.onInvalidJWTError?.(error)
        }
      })

    if (networkError) {
      options?.onCaptureNetworkError?.(networkError)
      console.error('[Network error]:', networkError)
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      if (error.message && retryErrorMessages.includes(error.message)) {
        if (count > 5) options?.onCaptureGraphQLError?.(error, operation)
        return count <= 5
      }

      const isQuery =
        operation &&
        operation.query &&
        operation.query.definitions &&
        Array.isArray(operation.query.definitions) &&
        operation.query.definitions.some(def => def.kind === 'OperationDefinition' && def.operation === 'query')
      if (isQuery) {
        if (count > 3) options?.onCaptureGraphQLError?.(error, operation)
        return count <= 3
      }

      return false
    },
  })

  return ApolloLink.from([errorLink, retryLink, authLink, httpLinks])
}
