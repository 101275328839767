import { gql, useMutation, useQuery } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { CartProductProps, CheckoutProps, ProductCheckoutOptions } from '@havppen/types/src/checkout'
import { DiscountUnit } from '@havppen/types/src/discount'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import {
  InvoiceProps,
  MemberInfoProps,
  OrderProductOptionsProps,
  PaymentMethod,
  PaymentType,
} from '@havppen/types/src/orderRecord'
import {
  ProductBriefProps,
  ProductDiscountBaseLimitUnit,
  ProductDiscountProps,
  ProductDiscountType,
} from '@havppen/types/src/product'
import { FormReplyProps } from '@havppen/types/src/productForm'
import { ShippingProps } from '@havppen/types/src/shipping'
import { notEmpty } from '@havppen/utils/src/array'
import { localStore } from '@havppen/utils/src/storageFactory'
import { CheckoutProduct, CheckoutSubproductOptions } from '@havppen/validates/src/payment'
import { message } from 'antd'
import dayjs from 'dayjs'
import DayJSTimezone from 'dayjs/plugin/timezone'
import DayJSUtc from 'dayjs/plugin/utc'
import { useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { SHIPPING_LOCAL_STORAGE_KEY } from 'src/components/checkout/CheckoutShippingForm'
import {
  INVOICE_LOCAL_STORAGE_KEY,
  PAYMENT_METHOD_LOCAL_STORAGE_KEY,
} from 'src/components/checkout/InitialCheckoutForm'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { GET_PRODUCT_DISCOUNT } from 'src/gql/product'
import { trpc } from 'src/helpers/trpc'
import { formatOrderProductToGtagItems } from 'src/utils/tracking'
import { NIL as NIL_UUID } from 'uuid'

dayjs.extend(DayJSUtc)
dayjs.extend(DayJSTimezone)

const defaultCheckoutResult: CheckoutProps = {
  checkoutId: null,
  productForms: [],
  orderProducts: [],
  orderDiscountableProductIds: [],
  orderDiscounts: [],
  totalPrice: 0,
  shippingMethods: [],
  totalShippingPrice: 0,
  currency: 'TWD',
}

export const useSaveReferralCode = () => {
  const searchParams = useSearchParams()
  const referralCode = searchParams?.get('referral')

  useEffect(() => {
    if (referralCode) {
      const expiredAt = dayjs().add(7, 'days').toDate().getTime()
      localStore.setItem('productReferralCode', JSON.stringify({ referralCode, expiredAt }))
    }
  }, [referralCode])

  useEffect(() => {
    if (referralCode) {
      window.gtag?.('set', { referral_code: referralCode })
    }
  }, [referralCode])
}

export const useSaveCouponCode = () => {
  const searchParams = useSearchParams()
  const couponCode = searchParams?.get('couponCode')

  useEffect(() => {
    if (couponCode) {
      const expiredAt = dayjs().add(7, 'days').toDate().getTime()
      localStore.setItem('couponCode', JSON.stringify({ couponCode, expiredAt }))
    }
  }, [couponCode])

  useEffect(() => {
    if (couponCode) {
      window.gtag?.('set', { coupon: couponCode })
    }
  }, [couponCode])
}

export const useReferralCode = () => {
  const searchParams = useSearchParams()
  const referralCode = searchParams?.get('referral')

  useEffect(() => {
    if (referralCode) {
      const expiredAt = dayjs().add(7, 'days').toDate().getTime()
      localStore.setItem('productReferralCode', JSON.stringify({ referralCode, expiredAt }))
    }
  }, [referralCode])

  useEffect(() => {
    if (referralCode) {
      window.gtag?.('set', { coupon: referralCode })
    }
  }, [referralCode])

  return referralCode
}

export const getReferralCode = () => {
  let referralCode: string | undefined = undefined
  const referralCodeData = JSON.parse(localStore.getItem('productReferralCode') || '{}')
  const referralExpiredAt = referralCodeData['expiredAt']
  if (referralExpiredAt && new Date().getTime() <= referralExpiredAt) {
    referralCode = referralCodeData['referralCode']
  }

  return referralCode ?? null
}

export const getCouponCode = () => {
  let couponCode: string | undefined = undefined
  const couponCodeData = JSON.parse(localStore.getItem('couponCode') || '{}')
  const couponExpiredAt = couponCodeData['expiredAt']
  if (couponExpiredAt && new Date().getTime() <= couponExpiredAt) {
    couponCode = couponCodeData['couponCode']
  }

  return couponCode ?? null
}

export const useCheckout = (
  checkoutProducts: CheckoutProduct[],
  discountId: string | null,
  referralCode: string | null,
  couponCode: string | null,
  point: number,
  shippings: ShippingProps[] | undefined = [],
  options?: ProductCheckoutOptions,
) => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { defaultCurrency } = useApp()

  const [messageApi, messageContextHolder] = message.useMessage()
  const [checkoutErrorMessages, setCheckoutErrorMessages] = useState<string[]>([])
  const [checkoutResult, setCheckoutResult] = useState<CheckoutProps>(defaultCheckoutResult)
  const { mutate: checkoutMutate, isLoading: isCheckingOut } = trpc.payment.checkoutOrder.useMutation({
    onSuccess({ code, result, message: msg }) {
      if (code === 'SUCCESS' && result) {
        setCheckoutResult(result)
        setCheckoutErrorMessages([...result.checkoutErrors])
      } else {
        setCheckoutErrorMessages([msg])
      }
    },
    onError(error) {
      messageApi.error(error.message)
    },
  })

  const checkout = useCallback(async () => {
    if (checkoutProducts.length === 0) {
      setCheckoutErrorMessages([])
      setCheckoutResult({
        ...defaultCheckoutResult,
        currency: defaultCurrency,
      })
      return
    }

    checkoutMutate({
      checkoutProducts,
      discountId,
      referralCode,
      couponCode,
      point,
      shipping: { shippings },
      options,
    })
  }, [
    checkoutProducts,
    checkoutMutate,
    discountId,
    referralCode,
    couponCode,
    point,
    shippings,
    options,
    defaultCurrency,
  ])

  const { mutateAsync: placeOrderMutate } = trpc.payment.placeOrder.useMutation({
    onError(error) {
      messageApi.error(error.message)
    },
  })

  const [isPlacingOrder, setIsPlacingOrder] = useState(false)
  const placeOrder = useCallback(
    async ({
      shippings = [],
      ...parameters
    }: {
      paymentType: PaymentType
      paymentMethod: PaymentMethod
      paymentInstallment?: string | null
      invoice: InvoiceProps
      shippings: ShippingProps[] | undefined
      memberInfo: MemberInfoProps | null
      formReplies?: FormReplyProps[]
      memberCardTokenId?: string | null
      subproductOptions?: CheckoutSubproductOptions
      options?: ProductCheckoutOptions
    }) => {
      setIsPlacingOrder(true)
      try {
        const referralCode = getReferralCode()
        const couponCode = getCouponCode()

        const memberCardTokenId = parameters.memberCardTokenId !== 'new' ? parameters.memberCardTokenId : undefined
        const checkoutItems = formatOrderProductToGtagItems(checkoutResult.orderProducts)
        const discountCode = checkoutResult.orderDiscounts.find(orderDiscount => Boolean(orderDiscount.options))
          ?.options?.['code']

        console.log('gtag add_payment_info')
        window.sendEvent?.('add_payment_info', {
          value: checkoutResult.totalPrice,
          currency: checkoutResult.currency,
          coupon: discountCode,
          payment_type: parameters.paymentMethod,
          items: checkoutItems,
        })

        window.fbq?.('track', 'AddPaymentInfo', {
          value: checkoutResult.totalPrice,
          currency: checkoutResult.currency,
          content_ids: checkoutItems.map(item => item.item_id),
          contents: checkoutItems.map(item => ({
            id: item.item_id,
            quantity: item.quantity,
          })),
        })

        shippings.forEach(shipping => {
          console.log('gtag add_shipping_info')
          window.sendEvent?.('add_shipping_info', {
            shipping_tier: shipping.method,
            value: checkoutResult.totalShippingPrice,
            currency: checkoutResult.currency,
            coupon: discountCode,
            items: checkoutItems,
          })
        })

        console.log('gtag begin_checkout')
        window.sendEvent?.('begin_checkout', {
          value: checkoutResult.totalPrice,
          coupon: discountCode,
          items: checkoutItems,
        })

        window.fbq?.('track', 'InitiateCheckout', {
          num_items: checkoutItems.length,
          value: checkoutResult.totalPrice,
          currency: checkoutResult.currency,
          content_ids: checkoutItems.map(item => item.item_id),
          contents: checkoutItems.map(item => ({
            id: item.item_id,
            quantity: item.quantity,
          })),
        })

        const {
          code,
          result,
          message: msg,
        } = await placeOrderMutate({
          ...parameters,
          shipping: { shippings },
          memberCardTokenId,
          checkoutProducts,
          discountId,
          point,
          referralCode,
          couponCode,
          options: {
            ...options,
            ...parameters.options,
            timezone: dayjs.tz.guess(),
          },
        })
        if (code === 'SUCCESS' && result?.jobId) {
          router.push(`/order/processing/${result.jobId}`)
        } else {
          messageApi.error(formatMessage({ id: `error.${code}`, defaultMessage: msg }, { message: msg }))
          setIsPlacingOrder(false)
        }

        try {
          localStore.setItem(INVOICE_LOCAL_STORAGE_KEY, JSON.stringify(parameters.invoice || {}))
          localStore.setItem(PAYMENT_METHOD_LOCAL_STORAGE_KEY, parameters.paymentMethod)
          localStore.setItem(SHIPPING_LOCAL_STORAGE_KEY, JSON.stringify(shippings?.[0] || {}))
        } catch {}
      } catch (error) {
        console.log(error)
        messageApi.error(error.message)
      }
    },
    [checkoutResult, discountId, options, placeOrderMutate, messageApi, formatMessage, router, point, checkoutProducts],
  )

  const totalPrice = useMemo(() => {
    return isCheckingOut ? undefined : checkoutResult.totalPrice
  }, [isCheckingOut, checkoutResult])

  return {
    isCheckingOut,
    isPlacingOrder,
    checkoutResult,
    checkoutErrorMessages,
    totalPrice,
    messageContextHolder,
    checkout,
    placeOrder,
  }
}

export const useRepayOrder = () => {
  const router = useRouter()
  const { formatMessage } = useIntl()

  const { mutate: repayOrderMutate, isLoading: isRepayingOrder } = trpc.payment.repayOrder.useMutation({
    onSuccess({ code, message: msg, result }) {
      if (code === 'SUCCESS' && result) {
        const { isPaymentEnd, orderId, paymentProvider, paymentFormHTML, paymentUrl } = result
        if (isPaymentEnd) {
          router.push(`/order/${orderId}/success`)
        } else if (paymentFormHTML) {
          document.write(paymentFormHTML)
        } else if (paymentUrl) {
          location.href = paymentUrl
        } else if (paymentProvider) {
          router.push(`/order/${orderId}/success`)
        } else {
          message.error(formatMessage({ id: 'error.E_NO_PAYMENT_PROVIDER' }))
        }
      } else {
        message.error(formatMessage({ id: `error.${code}`, defaultMessage: msg }, { message: msg }))
      }
    },
    onError(error) {
      message.error(error.message)
    },
  })
  const repayOrder = useCallback((orderId: string) => repayOrderMutate(orderId), [repayOrderMutate])

  return [repayOrder, { loading: isRepayingOrder }] as const
}

type ProductBriefItemProps = ProductBriefProps & {
  path: string | null
  isPhysical: boolean
}
export const useProductBrief = (productId: string) => {
  const { loading, error, data, refetch } = useQuery<types.GET_PRODUCT_BRIEF, types.GET_PRODUCT_BRIEFVariables>(
    gql`
      query GET_PRODUCT_BRIEF($productId: String!) {
        product(where: { id: { _eq: $productId } }, limit: 1) {
          id
          name
          list_price
          featured_image_url
          is_physical
          path
          product_status {
            status
          }
          product_owners {
            member {
              ...MEMBER_PUBLIC_COLUMNS
            }
          }
          product_categories {
            id
            category {
              id
              slug
              name
            }
          }
        }
      }
      ${MEMBER_PUBLIC_COLUMNS}
    `,
    {
      variables: {
        productId,
      },
    },
  )

  const productBrief = useMemo(() => {
    return ((data?.product ?? []).map(product => ({
      id: product.id as string,
      name: product.name as string,
      featuredImageUrl: product.featured_image_url,
      isPhysical: product.is_physical ?? false,
      path: product.path,
      listPrice: product.list_price,
      salePrice: null,
      status: product.product_status?.status,
      member: mapMemberPublicFromGql(product.product_owners[0]?.member),
      categories: product.product_categories.map(product_category => product_category.category).filter(notEmpty),
    }))[0] ?? null) as ProductBriefItemProps | null
  }, [data])

  return {
    loadingProductBrief: loading,
    errorProductBrief: error,
    productBrief,
    refetchProductBrief: refetch,
  }
}

export const useProductDiscount = (productDiscountId: string) => {
  const { loading, error, data, refetch } = useQuery<types.GET_PRODUCT_DISCOUNT, types.GET_PRODUCT_DISCOUNTVariables>(
    GET_PRODUCT_DISCOUNT,
    {
      variables: { productDiscountId },
    },
  )

  const productDiscount: ProductDiscountProps | null = useMemo(() => {
    return data?.product_discount_by_pk
      ? {
          id: data.product_discount_by_pk.id,
          type: data.product_discount_by_pk.type as ProductDiscountType,
          name: data.product_discount_by_pk.name,
          targetUnit: data.product_discount_by_pk.target_unit as DiscountUnit,
          targetAmount: data.product_discount_by_pk.target_amount,
          startAt: data.product_discount_by_pk.start_at ? new Date(data.product_discount_by_pk.start_at) : null,
          endAt: data.product_discount_by_pk.end_at ? new Date(data.product_discount_by_pk.end_at) : null,
          baseLimitUnit: data.product_discount_by_pk.base_limit_unit as ProductDiscountBaseLimitUnit,
          baseLimitAmount: data.product_discount_by_pk.base_limit_amount,
        }
      : null
  }, [data])

  return {
    loadingProductDiscount: loading,
    errorProductDiscount: error,
    productDiscount,
    refetchProductDiscount: refetch,
  }
}

export const useMemberCartProducts = () => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_CART_PRODUCTS,
    types.GET_MEMBER_CART_PRODUCTSVariables
  >(GET_MEMBER_CART_PRODUCTS, {
    skip: currentMemberId === NIL_UUID,
    variables: { memberId: currentMemberId },
    context: { important: true },
  })

  const cartProducts: CartProductProps[] = useMemo(() => {
    if (loading || error || !data || data.member_cart_product.length === 0) {
      return []
    }
    return data.member_cart_product.map(member_cart_product => ({
      id: member_cart_product.id,
      productId: member_cart_product.product_id,
      quantity: member_cart_product.quantity,
      isPurchased: (member_cart_product.product_possessions_aggregate.aggregate?.count || 0) > 0,
      options: member_cart_product.options,
    }))
  }, [data, error, loading])

  return {
    loadingCartProducts: loading,
    errorCartProducts: error,
    cartProducts,
    refetchCartProducts: refetch,
  }
}

export const useMemberCartProductCount = () => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_CART_PRODUCT_COUNT,
    types.GET_MEMBER_CART_PRODUCT_COUNTVariables
  >(
    gql`
      query GET_MEMBER_CART_PRODUCT_COUNT($memberId: String!) {
        member_cart_product_aggregate(where: { member_id: { _eq: $memberId } }) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      skip: currentMemberId === NIL_UUID,
      variables: { memberId: currentMemberId },
    },
  )

  return {
    loadingCartProductCount: loading,
    errorCartProductCount: error,
    cartProductCount: data?.member_cart_product_aggregate.aggregate?.count || 0,
    refetchCartProductCount: refetch,
  }
}

export const useInsertMemberCartProducts = () => {
  const [insertMemberCartProductHandler] = useMutation<
    types.INSERT_MEMBER_CART_PRODUCT,
    types.INSERT_MEMBER_CART_PRODUCTVariables
  >(gql`
    mutation INSERT_MEMBER_CART_PRODUCT($objects: [member_cart_product_insert_input!]!) {
      insert_member_cart_product(
        objects: $objects
        on_conflict: { constraint: member_cart_product_product_id_member_id_key, update_columns: [options] }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `)

  return useCallback(
    (cartProducts: CartProductProps[]) => {
      return insertMemberCartProductHandler({
        variables: {
          objects: cartProducts.map(cartProduct => ({
            product_id: cartProduct.productId,
            options: cartProduct.options,
          })),
        },
      })
    },
    [insertMemberCartProductHandler],
  )
}

export const useUpdateMemberCartProductOptions = () => {
  const [updateMemberCartProductOptionsHandler] = useMutation<
    types.UPDATE_MEMBER_CART_PRODUCT_OPTIONS,
    types.UPDATE_MEMBER_CART_PRODUCT_OPTIONSVariables
  >(gql`
    mutation UPDATE_MEMBER_CART_PRODUCT_OPTIONS($memberCartProductId: uuid!, $options: jsonb!) {
      update_member_cart_product_by_pk(pk_columns: { id: $memberCartProductId }, _set: { options: $options }) {
        id
      }
    }
  `)

  return useCallback(
    (memberCartProductId: string, options: OrderProductOptionsProps) => {
      return updateMemberCartProductOptionsHandler({
        variables: {
          memberCartProductId,
          options,
        },
      })
    },
    [updateMemberCartProductOptionsHandler],
  )
}

export const useUpdateMemberCartProductQuantity = () => {
  const [updateMemberCartProductQuantityHandler] = useMutation<
    types.UPDATE_MEMBER_CART_PRODUCT_QUANTITY,
    types.UPDATE_MEMBER_CART_PRODUCT_QUANTITYVariables
  >(gql`
    mutation UPDATE_MEMBER_CART_PRODUCT_QUANTITY($memberCartProductId: uuid!, $quantity: Int!) {
      update_member_cart_product_by_pk(pk_columns: { id: $memberCartProductId }, _set: { quantity: $quantity }) {
        id
      }
    }
  `)

  return useCallback(
    (memberCartProductId: string, quantity: number) => {
      return updateMemberCartProductQuantityHandler({
        variables: {
          memberCartProductId,
          quantity,
        },
      })
    },
    [updateMemberCartProductQuantityHandler],
  )
}

export const useDeleteMemberCartProducts = () => {
  const [deleteMemberCartProductsHandler] = useMutation<
    types.DELETE_MEMBER_CART_PRODUCTS,
    types.DELETE_MEMBER_CART_PRODUCTSVariables
  >(gql`
    mutation DELETE_MEMBER_CART_PRODUCTS($condition: member_cart_product_bool_exp!) {
      delete_member_cart_product(where: $condition) {
        returning {
          id
        }
      }
    }
  `)

  return useCallback(
    (memberCartProductIds?: string[]) => {
      return deleteMemberCartProductsHandler({
        variables: {
          condition: {
            id: memberCartProductIds ? { _in: memberCartProductIds } : undefined,
          },
        },
      })
    },
    [deleteMemberCartProductsHandler],
  )
}

export const useDefaultPaymentMethod = () => {
  const { settings } = useApp()
  const defaultPaymentMethod: PaymentMethod = useMemo(() => {
    return settings['payment_api.credit_card_one_time'] === 'true'
      ? 'creditCard'
      : settings['payment_api.atm'] === 'true'
      ? 'atm'
      : Object.keys(settings)
          .filter(key => settings[key] === 'true')
          .some(key => key.startsWith('payment_api.credit_card_installment'))
      ? 'creditCardInstallment'
      : settings['payment_api.union_pay'] === 'true'
      ? 'unionPay'
      : settings['payment_api.wechat_pay'] === 'true'
      ? 'wechatPay'
      : settings['payment_api.ali_pay'] === 'true'
      ? 'aliPay'
      : settings['payment_api.cvs'] === 'true'
      ? 'cvs'
      : settings['payment_api.barcode'] === 'true'
      ? 'barcode'
      : 'creditCard'
  }, [settings])

  return defaultPaymentMethod
}

export const GET_MEMBER_CART_PRODUCTS = gql`
  query GET_MEMBER_CART_PRODUCTS($memberId: String!) {
    member_cart_product(where: { product: { deleted_at: { _is_null: true } } }, order_by: { created_at: desc }) {
      id
      member_id
      product_id
      quantity
      options
      created_at
      product_possessions_aggregate(where: { member_id: { _eq: $memberId } }) {
        aggregate {
          count
        }
      }
    }
  }
`
