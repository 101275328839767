'use client'

import dynamic from 'next/dynamic'
import { createContext, useEffect, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'

const ConfirmEmailModal = dynamic(() => import('./ConfirmEmailModal'), { ssr: false })
const GlobalAuthModal = dynamic(() => import('./AuthModal').then(AuthModal => AuthModal.GlobalAuthModal), {
  ssr: false,
})

export type AuthState = 'login' | 'register' | 'social-login-setting' | undefined

const AuthModalContext = createContext<{
  visible: boolean
  confirmEmailVisible: boolean
  beforeLoginPath: string
  afterLoginPath: string
  authState?: AuthState
  openAuthModal?: () => void
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setConfirmEmailVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setBeforeLoginPath?: React.Dispatch<React.SetStateAction<string>>
  setAfterLoginPath?: React.Dispatch<React.SetStateAction<string>>
  setAuthState?: React.Dispatch<React.SetStateAction<AuthState>>
}>({ visible: false, confirmEmailVisible: false, beforeLoginPath: '/', afterLoginPath: '/' })

export const AuthModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [confirmEmailVisible, setConfirmEmailVisible] = useState(false)
  const [beforeLoginPath, setBeforeLoginPath] = useState('/')
  const [afterLoginPath, setAfterLoginPath] = useState('/')

  const [authState, setAuthState] = useState<AuthState>()

  const { isAuthenticated, currentMember } = useAuth()
  const { settings } = useApp()
  const isEmailConfirmationRequired = Boolean(settings['auth.is_email_confirmation_required'])

  useEffect(() => {
    if (isAuthenticated && !currentMember?.confirmedEmailAt && isEmailConfirmationRequired) {
      setConfirmEmailVisible(true)
    }
  }, [isAuthenticated, currentMember?.confirmedEmailAt, isEmailConfirmationRequired])

  return (
    <AuthModalContext.Provider
      value={{
        visible,
        confirmEmailVisible,
        beforeLoginPath,
        afterLoginPath,
        authState,
        setVisible,
        setConfirmEmailVisible,
        setBeforeLoginPath,
        setAfterLoginPath,
        setAuthState,
        openAuthModal: () => setVisible(true),
      }}
    >
      {children}
      <GlobalAuthModal />
      <ConfirmEmailModal />
    </AuthModalContext.Provider>
  )
}

export default AuthModalContext
