'use client'

import { captureException } from '@sentry/nextjs'
import { Button } from 'antd'
import { Component, ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  errorInfo: ErrorInfo | null
}

const StyledErrorFallbackPage = styled.div`
  text-align: center;
  min-height: 100vh;

  .title {
    font-size: 20px;
    color: var(--theme-primary-color);
  }
`

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorInfo: null,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: null }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    window.sendEvent?.('exception', {
      description: error.toString(),
      fatal: true,
    })
    console.error('Uncaught error:', error, errorInfo)
    captureException(error)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <StyledErrorFallbackPage className="d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="mb-5">
              <div className="title mb-4">抱歉，出錯了！工程師正在緊急搶修中</div>
              <div>{this.state?.errorInfo?.toString()}</div>
            </div>

            <Button
              size="large"
              type="primary"
              shape="round"
              className="px-5"
              onClick={() => this.setState({ hasError: false, errorInfo: null })}
            >
              返回
            </Button>
          </div>
        </StyledErrorFallbackPage>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
