import { OrderProductDetailProps } from '@havppen/types/src/orderRecord'
import { IncomingMessage } from 'http'

export const getTrackingDataFromRequest = (req: IncomingMessage) => {
  const {
    cookie,
    accept,
    path,
    authorization,
    ['accept-encoding']: acceptEncoding,
    ['x-vercel-sc-headers']: vercelScHeaders,
    ['x-vercel-sc-host']: vercelScHost,
    ['x-vercel-deployment-url']: vercelDeploymentUrl,
    host,
    connection,
    ...tracking
  } = req.headers

  return {
    ip: req.headers['x-forwarded-for'] || req.connection.remoteAddress,
    ...tracking,
  }
}

export const parseTrackingDataFromRequest = (req: IncomingMessage) => {
  const {
    cookie,
    accept,
    path,
    authorization,
    origin,
    referer,
    host,
    forwarded,
    connection,
    ['content-type']: contentType,
    ['content-length']: contentLength,
    ['accept-encoding']: acceptEncoding,
    ['transfer-encoding']: transferEncoding,
    ['x-vercel-sc-headers']: vercelScHeaders,
    ['x-vercel-sc-host']: vercelScHost,
    ['x-vercel-proxy-signature']: vercelProxySignature,
    ['x-vercel-id']: vercelId,
    ['x-vercel-deployment-url']: vercelDeploymentUrl,
    ['x-vercel-forwarded-for']: vercelForwardedFor,
    ['x-vercel-proxied-for']: vercelProxiedFor,
    ['x-vercel-proxy-signature-ts']: vercelProxySignatureTs,
    ['x-content-type-options']: contentTypeOptions,
    ['x-dns-prefetch-control']: dnsPrefetchControl,
    ['x-matched-path']: matchedPath,
    ['x-forwarded-for']: xForwardedFor,
    ['x-forwarded-host']: xForwardedHost,
    ['x-forwarded-proto']: xForwardedProto,
    ['x-forwarded-port']: xForwardedPort,
    ['x-middleware-invoke']: xMiddlewareInvoke,
    ['x-xss-protection']: xXssProtection,
    ['x-invoke-query']: xInvokeQuery,
    ['x-invoke-path']: xInvokePath,
    ['x-real-ip']: xRealIp,
    ['cache-control']: cacheControl,
    ['sec-fetch-dest']: secFetchDest,
    ['sec-fetch-mode']: secFetchMode,
    ['sec-fetch-site']: secFetchSite,
    ['sec-ch-ua-platform']: uaPlatform,
    ['sec-ch-ua-mobile']: uaMobile,
    ['sec-ch-ua']: ua,
    ['x-vercel-ip-country']: ipCountry,
    ['x-vercel-ip-city']: ipCity,
    ['x-vercel-ip-latitude']: ipLatitude,
    ['x-vercel-ip-longitude']: ipLongitude,
    ['x-vercel-ip-timezone']: ipTimezone,
    ['x-vercel-ip-country-region']: ipCountryRegion,
    ['sec-gpc']: secGpc,
    ['user-agent']: userAgent,
    ['accept-language']: acceptLanguage,
    ...tracking
  } = req.headers

  return {
    ip:
      ((xRealIp || xForwardedFor || vercelForwardedFor || vercelProxiedFor) as string | undefined) ||
      req.connection.remoteAddress,
    cookie,
    host,
    path,
    origin,
    referer,
    ipCountry,
    ipCity,
    ipLatitude,
    ipLongitude,
    ipTimezone,
    uaPlatform,
    ipCountryRegion,
    uaMobile,
    ua: (ua || userAgent) as string | undefined,
    acceptLanguage,
    ...tracking,
  }
}

export const formatOrderProductToGtagItems = (orderProducts: OrderProductDetailProps[]) => {
  return orderProducts.map(({ productId, product, name, price, quantity, options }, index) => {
    const [productType] = productId.split('_')
    const categoryName = product?.categories[0]?.name || ''
    const memberName = product?.member?.displayName || ''
    const discount = (options?.listPrice || price) - price

    return {
      item_id: productId,
      item_name: name,
      price,
      discount,
      index,
      quantity,
      item_category: productType,
      item_category2: categoryName,
      item_category3: memberName,
    }
  })
}
