import { gql, useMutation, useQuery } from '@apollo/client'
import { GET_MEMBER_BY_ID } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { useEffect, useMemo, useState } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { GET_MEMBER_PUBLICS, MEMBER_PUBLIC_PROFILE_FRAGMENT } from 'src/gql/member'
import {
  MemberProfileDataFieldsProps,
  MemberProps,
  MemberPublicBriefProps,
  MemberPublicDetailProps,
  MemberReferrer,
} from 'src/types/member'
import { NIL as NIL_UUID } from 'uuid'
import { formatGqlMemberPublics, mapMemberPublicsQueryOptionsToGqlVariables, MemberPublicsQueryOptions } from './member'

export const useMemberPublic = (options: { memberId?: string; memberName?: string; isDetailShown?: boolean }) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_MEMBER_PUBLIC, types.GET_MEMBER_PUBLICVariables>(
    GET_MEMBER_PUBLIC,
    {
      skip: options.memberId === NIL_UUID,
      variables: {
        condition: {
          id:
            typeof options.memberId !== 'undefined'
              ? { _eq: options.memberId }
              : options.memberName
              ? undefined
              : { _eq: '' },
          name: options.memberName ? { _eq: options.memberName } : undefined,
        },
        isDetailShown: false,
      },
    },
  )

  const member: MemberPublicBriefProps | null =
    loading || error || !data || data.member_public.length === 0
      ? null
      : mapMemberPublicFromGql(data.member_public[0], { defaultAvatarUrl })

  return {
    loadingMember: loading,
    errorMember: error,
    member,
    refetchMember: refetch,
  }
}

export const useMemberPublicDetail = (options: { memberId?: string; memberName?: string }) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_MEMBER_PUBLIC, types.GET_MEMBER_PUBLICVariables>(
    GET_MEMBER_PUBLIC,
    {
      skip: options.memberId === NIL_UUID,
      variables: {
        isDetailShown: true,
        condition: {
          id:
            typeof options.memberId !== 'undefined'
              ? { _eq: options.memberId }
              : options.memberName
              ? undefined
              : { _eq: '' },
          _or: options.memberName
            ? [{ name: { _eq: options.memberName } }, { display_name: { _eq: options.memberName } }]
            : undefined,
        },
      },
    },
  )

  const member = useMemo(() => {
    return ((data?.member_public ?? []).map(member_public => ({
      id: member_public.id as string,
      name: member_public.name,
      username: member_public.username,
      displayName: member_public.display_name || member_public.name || member_public.username,
      avatarUrl: member_public.avatar_url || defaultAvatarUrl,
      abstract: member_public.abstract,
      title: member_public.title,
      age: member_public.age_year,
      profileData: member_public.profile_data,
      coursesPurchaseCount: member_public.course_possessions_aggregate?.aggregate?.count || 0,
      totalProductCount: member_public.product_owners_aggregate?.aggregate?.count || 0,
      followerCount: member_public.member_followers_aggregate?.aggregate?.count || 0,
      isPrivate: member_public.is_private,
    }))[0] ?? null) as MemberPublicDetailProps | null
  }, [data, defaultAvatarUrl])

  return {
    loadingMember: loading,
    errorMember: error,
    member,
    refetchMember: refetch,
  }
}

export const useMemberPublics = (options?: MemberPublicsQueryOptions) => {
  const [isNoMore, setIsNoMore] = useState(false)
  const { defaultAvatarUrl, id: appId } = useApp()

  const { loading, error, data, refetch, fetchMore } = useQuery<
    types.GET_MEMBER_PUBLICS,
    types.GET_MEMBER_PUBLICSVariables
  >(GET_MEMBER_PUBLICS, {
    skip: options?.memberId === NIL_UUID,
    variables: mapMemberPublicsQueryOptionsToGqlVariables(options || {}, { appId }),
  })

  const members: MemberPublicDetailProps[] | MemberPublicBriefProps[] = useMemo(() => {
    return formatGqlMemberPublics(data, {
      defaultAvatarUrl,
      ...options,
    })
  }, [data, defaultAvatarUrl, options])

  const membersCount = data?.member_public_aggregate.aggregate?.count || 0
  useEffect(() => {
    if (options?.limit && options.limit >= membersCount) {
      setIsNoMore(true)
    } else if (!options?.limit) {
      setIsNoMore(true)
    }
  }, [membersCount, options?.limit])

  return {
    loadingMembers: loading,
    errorMembers: error,
    members,
    refetchMembers: async () => {
      setIsNoMore(false)
      await refetch()
    },
    membersCount,
    fetchMoreMembers: isNoMore
      ? undefined
      : () =>
          fetchMore({
            variables: { offset: data?.member_public.length || 0 },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }
              if (fetchMoreResult.member_public.length < (options?.limit || 10)) {
                setIsNoMore(true)
              }
              return {
                ...prev,
                member_public: [...prev.member_public, ...fetchMoreResult.member_public],
              }
            },
          }),
  }
}

export const useMember = (memberId: string) => {
  const { defaultAvatarUrl } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_MEMBER_BY_ID, types.GET_MEMBER_BY_IDVariables>(
    GET_MEMBER_BY_ID,
    {
      skip: memberId === NIL_UUID,
      variables: { memberId },
    },
  )

  const member: MemberProps | null = useMemo(
    () =>
      !data || !data.member_by_pk
        ? null
        : {
            id: data.member_by_pk.id,
            name: data.member_by_pk.name,
            displayName: data.member_by_pk.display_name || data.member_by_pk.name,
            username: data.member_by_pk.username,
            avatarUrl: data.member_by_pk.avatar_url || defaultAvatarUrl,
            abstract: data.member_by_pk.abstract,
            title: data.member_by_pk.title,
            email: data.member_by_pk.email,
            unconfirmedEmail: data.member_by_pk.unconfirmed_email,
            unconfirmedPhone: data.member_by_pk.unconfirmed_phone,
            sex: data.member_by_pk.sex,
            phone: data.member_by_pk.phone,
            metadata: data.member_by_pk.metadata,
            birthday: data.member_by_pk.birthday ? new Date(data.member_by_pk.birthday) : null,
            loginAt: data.member_by_pk.login_at ? new Date(data.member_by_pk.login_at) : null,
            confirmedEmailAt: data.member_by_pk.confirmed_email_at
              ? new Date(data.member_by_pk.confirmed_email_at)
              : null,
            confirmedPhoneAt: data.member_by_pk.confirmed_phone_at
              ? new Date(data.member_by_pk.confirmed_phone_at)
              : null,
            createdAt: data.member_by_pk.created_at ? new Date(data.member_by_pk.created_at) : null,
            profileData: data.member_by_pk.profile_data,
          },
    [data, defaultAvatarUrl],
  )

  return {
    loadingMember: loading,
    errorMember: error,
    member,
    refetchMember: refetch,
  }
}

export const useMemberSettings = (options: { memberId?: string; memberName?: string }) => {
  const { loading, error, data, refetch } = useQuery<types.GET_MEMBER_SETTINGS, types.GET_MEMBER_SETTINGSVariables>(
    GET_MEMBER_SETTINGS,
    {
      skip: options.memberId === NIL_UUID,
      variables: {
        condition: {
          member_id: options.memberId ? { _eq: options.memberId } : undefined,
          member: options.memberName
            ? { _or: [{ name: { _eq: options.memberName } }, { display_name: { _eq: options.memberName } }] }
            : undefined,
        },
      },
    },
  )

  const memberSettings: { [key: string]: string } =
    loading || error || !data || data.member_setting.length === 0
      ? {}
      : data.member_setting.reduce((settings, member_setting) => {
          settings[member_setting.key] = member_setting.value
          return settings
        }, {} as { [key: string]: string })

  return {
    loadingMemberSettings: loading,
    errorMemberSettings: error,
    memberSettings,
    refetchMemberSettings: refetch,
  }
}

export const useMemberMutation = (memberId: string) => {
  const [updateMemberMetadataHandler] = useMutation<
    types.UPDATE_MEMBER_METADATA,
    types.UPDATE_MEMBER_METADATAVariables
  >(gql`
    mutation UPDATE_MEMBER_METADATA($memberId: String!, $metadata: jsonb) {
      update_member_by_pk(pk_columns: { id: $memberId }, _set: { metadata: $metadata }) {
        id
        metadata
      }
    }
  `)
  const updateMemberMetadata = (metadata: { [key: string]: any }) => {
    return updateMemberMetadataHandler({
      variables: {
        memberId,
        metadata,
      },
    })
  }

  const [appendMemberMetadataHandler] = useMutation<
    types.APPEND_MEMBER_METADATA,
    types.APPEND_MEMBER_METADATAVariables
  >(gql`
    mutation APPEND_MEMBER_METADATA($memberId: String!, $metadata: jsonb) {
      update_member_by_pk(pk_columns: { id: $memberId }, _append: { metadata: $metadata }) {
        id
        metadata
      }
    }
  `)
  const appendMemberMetadata = (metadata: { [key: string]: any }) => {
    return appendMemberMetadataHandler({
      variables: {
        memberId,
        metadata,
      },
    })
  }

  const [updateMemberProfileHandler] = useMutation<
    types.UPDATE_MEMBER_PROFILE_DATA,
    types.UPDATE_MEMBER_PROFILE_DATAVariables
  >(gql`
    mutation UPDATE_MEMBER_PROFILE_DATA($memberId: String!, $profileData: jsonb) {
      update_member_by_pk(pk_columns: { id: $memberId }, _set: { profile_data: $profileData }) {
        id
        profile_data
      }
    }
  `)
  const updateMemberProfile = (profileData: MemberProfileDataFieldsProps) => {
    return updateMemberProfileHandler({
      variables: {
        memberId,
        profileData,
      },
    })
  }

  const [updateMemberHandler] = useMutation<types.UPDATE_MEMBER_DATA, types.UPDATE_MEMBER_DATAVariables>(gql`
    mutation UPDATE_MEMBER_DATA($memberId: String!, $object: member_set_input, $append: member_append_input) {
      update_member_by_pk(pk_columns: { id: $memberId }, _set: $object, _append: $append) {
        id
        metadata
      }
    }
  `)
  const updateMember = (params: { set?: types.member_set_input; append?: types.member_append_input }) => {
    return updateMemberHandler({
      variables: {
        memberId,
        object: params.set ?? {},
        append: params.append ?? {},
      },
    })
  }

  return {
    updateMemberMetadata,
    appendMemberMetadata,
    updateMemberProfile,
    updateMember,
  }
}

export const useIsMemberFollowing = (targetMemberId: string) => {
  const { currentMemberId, isAuthenticated } = useAuth()
  const { loading, error, data, refetch } = useQuery<types.GET_MEMBER_FOLLOWING, types.GET_MEMBER_FOLLOWINGVariables>(
    GET_MEMBER_FOLLOWING,
    {
      skip: currentMemberId === NIL_UUID,
      variables: {
        targetMemberId,
        memberId: currentMemberId,
      },
    },
  )

  const isMemberFollowing = useMemo(
    () =>
      loading || error || !data || !data?.member_following_by_pk
        ? false
        : isAuthenticated
        ? Boolean(data.member_following_by_pk.member_id)
        : false,
    [isAuthenticated, data, error, loading],
  )
  return {
    loadingIsMemberFollowing: loading,
    errorIsMemberFollowing: error,
    isMemberFollowing,
    refetchIsMemberFollowing: refetch,
  }
}

export const useMemberFollowingIds = (memberId?: string) => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_FOLLOWING_IDS,
    types.GET_MEMBER_FOLLOWING_IDSVariables
  >(GET_MEMBER_FOLLOWING_IDS, {
    skip: (memberId || currentMemberId) === NIL_UUID,
    variables: { memberId: memberId || currentMemberId },
  })

  const memberFollowingIds: string[] =
    loading || error || !data ? [] : data.member_following.map(member_following => member_following.target_member_id)
  return {
    loadingMemberFollowingIds: loading,
    errorMemberFollowingIds: error,
    memberFollowingIds,
    refetchMemberFollowingIds: refetch,
  }
}

export const useMemberReferralCodeCount = (referralCode: string) => {
  const { loading, data, error, refetch } = useQuery<
    types.GET_MEMBER_REFERRAL_CODE_COUNT,
    types.GET_MEMBER_REFERRAL_CODE_COUNTVariables
  >(
    gql`
      query GET_MEMBER_REFERRAL_CODE_COUNT($referralCodeData: jsonb!) {
        member_public_aggregate(where: { profile_data: { _contains: $referralCodeData } }) {
          aggregate {
            count
          }
        }
      }
    `,
    { variables: { referralCodeData: { referralCode } } },
  )

  return {
    loadingMemberReferralCodeCount: loading,
    errorMemberReferralCodeCount: error,
    memberReferralCodeCount: data?.member_public_aggregate.aggregate?.count || 0,
    refetchMemberReferralCodeCount: refetch,
  }
}

export const useMemberReferrer = (memberId: string) => {
  const { loading, data, error, refetch } = useQuery<types.GET_MEMBER_REFERRER, types.GET_MEMBER_REFERRERVariables>(
    gql`
      query GET_MEMBER_REFERRER($memberId: String!) {
        member_referrer(where: { member_id: { _eq: $memberId } }) {
          id
          member_id
          referrer_member_id
          created_at
          referral_code
        }
      }
    `,
    {
      skip: memberId === NIL_UUID,
      variables: { memberId },
    },
  )

  const memberReferrer: MemberReferrer | null = useMemo(
    () =>
      (data?.member_referrer ?? []).map(member_referrer => ({
        id: member_referrer.id,
        memberId: member_referrer.member_id,
        referrerMemberId: member_referrer.referrer_member_id,
        referralCode: member_referrer.referral_code,
        createdAt: new Date(member_referrer.created_at),
      }))[0] || null,
    [data],
  )
  return {
    loadingMemberReferrer: loading,
    errorMemberReferrer: error,
    memberReferrer,
    refetchMemberReferrer: refetch,
  }
}

export const useMemberFollowMutation = (targetMemberId: string) => {
  const { currentMemberId } = useAuth()

  const [insertMemberFollowingHandler] = useMutation<
    types.INSERT_MEMBER_FOLLOWING,
    types.INSERT_MEMBER_FOLLOWINGVariables
  >(
    gql`
      mutation INSERT_MEMBER_FOLLOWING($targetMemberId: String!) {
        insert_member_following_one(object: { target_member_id: $targetMemberId }) {
          member_id
          target_member_id
        }
      }
    `,
    {
      optimisticResponse: {
        insert_member_following_one: currentMemberId
          ? {
              member_id: currentMemberId,
              target_member_id: targetMemberId,
              __typename: 'member_following',
            }
          : null,
      },
      update(cache, { data }) {
        cache.writeQuery<types.GET_MEMBER_FOLLOWING, types.GET_MEMBER_FOLLOWINGVariables>({
          query: GET_MEMBER_FOLLOWING,
          data: {
            member_following_by_pk: data?.insert_member_following_one
              ? {
                  member_id: data.insert_member_following_one.member_id,
                  target_member_id: data.insert_member_following_one.target_member_id,
                  __typename: 'member_following',
                }
              : null,
          },
          variables: {
            memberId: data?.insert_member_following_one?.member_id || '',
            targetMemberId,
          },
        })
      },
    },
  )
  const insertMemberFollowing = () => {
    return insertMemberFollowingHandler({ variables: { targetMemberId } })
  }

  const [deleteMemberFollowingHandler] = useMutation<
    types.DELETE_MEMBER_FOLLOWING,
    types.DELETE_MEMBER_FOLLOWINGVariables
  >(
    gql`
      mutation DELETE_MEMBER_FOLLOWING($targetMemberId: String!, $memberId: String!) {
        delete_member_following_by_pk(target_member_id: $targetMemberId, member_id: $memberId) {
          target_member_id
          member_id
        }
      }
    `,
    {
      optimisticResponse: {
        delete_member_following_by_pk: currentMemberId
          ? {
              member_id: currentMemberId,
              target_member_id: targetMemberId,
              __typename: 'member_following',
            }
          : null,
      },
      update(cache, { data }) {
        cache.writeQuery<types.GET_MEMBER_FOLLOWING, types.GET_MEMBER_FOLLOWINGVariables>({
          query: GET_MEMBER_FOLLOWING,
          data: {
            member_following_by_pk: null,
          },
          variables: {
            memberId: data?.delete_member_following_by_pk?.member_id || '',
            targetMemberId,
          },
        })
      },
    },
  )
  const deleteMemberFollowing = () => {
    return deleteMemberFollowingHandler({ variables: { targetMemberId, memberId: currentMemberId } })
  }

  return {
    insertMemberFollowing,
    deleteMemberFollowing,
  }
}

type MemberCardTokenProps = {
  id: string
  name: string
  cardIssuer: string | null
}
export const useMemberCardTokens = () => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_CARD_TOKENS,
    types.GET_MEMBER_CARD_TOKENSVariables
  >(GET_MEMBER_CARD_TOKENS, {
    skip: currentMemberId === NIL_UUID,
    variables: {
      condition: {
        member_id: { _eq: currentMemberId },
        is_valid: { _eq: true },
      },
    },
  })

  const memberCardTokens: MemberCardTokenProps[] = useMemo(() => {
    return !data
      ? []
      : data.member_card_token.map(member_card_token => ({
          id: member_card_token.id,
          name: member_card_token.name,
          cardIssuer: member_card_token.card_issuer,
        }))
  }, [data])
  return {
    loadingMemberCardTokens: loading,
    errorMemberCardTokens: error,
    memberCardTokens,
    refetchMemberCardTokens: refetch,
  }
}

const GET_MEMBER_FOLLOWING = gql`
  query GET_MEMBER_FOLLOWING($memberId: String!, $targetMemberId: String!) {
    member_following_by_pk(target_member_id: $targetMemberId, member_id: $memberId) {
      member_id
      target_member_id
    }
  }
`

const GET_MEMBER_FOLLOWING_IDS = gql`
  query GET_MEMBER_FOLLOWING_IDS($memberId: String!) {
    member_following(where: { member_id: { _eq: $memberId } }) {
      member_id
      target_member_id
    }
  }
`

const GET_MEMBER_PUBLIC = gql`
  query GET_MEMBER_PUBLIC($condition: member_public_bool_exp, $isDetailShown: Boolean!) {
    member_public(where: $condition, limit: 1) {
      ...MEMBER_PUBLIC_PROFILE_FRAGMENT
    }
  }
  ${MEMBER_PUBLIC_PROFILE_FRAGMENT}
`

const GET_MEMBER_SETTINGS = gql`
  query GET_MEMBER_SETTINGS($condition: member_setting_bool_exp!) {
    member_setting(where: $condition) {
      key
      value
    }
  }
`
const GET_MEMBER_CARD_TOKENS = gql`
  query GET_MEMBER_CARD_TOKENS($condition: member_card_token_bool_exp) {
    member_card_token(where: $condition) {
      id
      name
      card_issuer
    }
  }
`
