'use client'

import { localStore } from '@havppen/utils/src/storageFactory'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { antdLangMapping, DEFAULT_LOCAL, supportedLanguages, translationMessageMapping } from 'src/libs/languageLib'
import { useApp } from './AppContext'

type LanguageProps = {
  currentLanguage: string
  setCurrentLanguage?: (language: string) => void
}

const LanguageContext = createContext<LanguageProps>({
  currentLanguage: DEFAULT_LOCAL,
})

export const LanguageProvider: React.FC<React.PropsWithChildren<{ defaultLocale?: string }>> = ({
  defaultLocale = DEFAULT_LOCAL,
  children,
}) => {
  const { enabledModules, defaultLocale: defaultAppLocale } = useApp()
  const defaultLanguage = defaultAppLocale ?? defaultLocale
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage)

  useEffect(() => {
    const browserLanguage = navigator.language
    const cachedLanguage = localStore.getItem('app.language')

    setCurrentLanguage(
      enabledModules.locale
        ? typeof cachedLanguage === 'string' && supportedLanguages.includes(cachedLanguage)
          ? cachedLanguage
          : supportedLanguages.includes(browserLanguage)
          ? browserLanguage
          : defaultLanguage
        : defaultLanguage,
    )
  }, [defaultLanguage, enabledModules])

  useEffect(() => {
    dayjs.locale(currentLanguage.toLowerCase())
  }, [currentLanguage])

  const messages = useMemo(() => translationMessageMapping[currentLanguage], [currentLanguage])
  const antdLocale = useMemo(() => {
    return antdLangMapping[currentLanguage as keyof typeof antdLangMapping]
  }, [currentLanguage])

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        setCurrentLanguage: (language: string) => {
          if (supportedLanguages.includes(language)) {
            localStore.setItem('app.language', language)
            setCurrentLanguage(language)
          }
        },
      }}
    >
      <ConfigProvider locale={antdLocale}>
        <IntlProvider defaultLocale={defaultLanguage} locale={currentLanguage} messages={messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageContext
