'use client'

import { localStore } from '@havppen/utils/src/storageFactory'
import { message } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { ReadonlyURLSearchParams } from 'next/navigation'

export const validateImage = (file: RcFile | File | Blob, fileSize?: number) => {
  const isImage = file.type.startsWith('image')
  if (!isImage) {
    message.error('請上傳圖片格式')
  }
  const size = fileSize || 2 * 1024 * 1024
  const inSize = file.size < size
  if (!inSize) {
    message.error(`圖片大小請小於 ${(size / 1024 / 1024).toFixed(0)}MB`)
  }
  return isImage && inSize
}

export const copyToClipboard = (copyText: string) => {
  const textArea = document.createElement('textarea')
  textArea.value = copyText
  textArea.style.position = 'fixed' //avoid scrolling to bottom
  textArea.contentEditable = 'true'
  textArea.readOnly = false
  document.body.appendChild(textArea)

  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange()
    range.selectNodeContents(textArea)
    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
    textArea.setSelectionRange(0, 999999)
  } else {
    textArea.focus()
    textArea.select()
  }

  /* Copy the text inside the text field */
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export const mobileBrowserCheck = () => {
  if (typeof navigator === 'undefined') {
    return false
  }

  const ua = navigator.userAgent
  const isAndroid = /Android/i.test(ua)
  const isChrome = /Chrome/i.test(ua)
  const isFirefox = /Firefox/i.test(ua)
  const isOpera = /Opera/i.test(ua)
  const isSafari = /Safari/i.test(ua) && !isChrome
  const isIOS = /iPhone|iPad|iPod/i.test(ua)
  const isMobile = isAndroid || isIOS
  const isMobileSafari = isSafari && isMobile
  const isMobileChrome = isChrome && isMobile
  const isMobileFirefox = isFirefox && isMobile
  const isMobileOpera = isOpera && isMobile
  return isMobileSafari || isMobileChrome || isMobileFirefox || isMobileOpera
}

export const getDocumentAttributeValue = (attribute: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(attribute)

export const scrollToElement = (element: HTMLElement) => {
  const headerHeight = parseInt(getDocumentAttributeValue('--header-height'))
  const bounding = element.getBoundingClientRect()
  window.scrollTo({
    left: 0,
    top: window.scrollY + bounding.top - headerHeight,
    behavior: 'smooth',
  })
}

export const getSignUpReferrerCode = (searchParams: ReadonlyURLSearchParams | URLSearchParams | null) => {
  const referralCode = localStore.getItem('signUpReferralCode') ?? (searchParams?.get('referrer') as string | undefined)
  return referralCode
}
