'use client'

import Fingerprint2 from '@fingerprintjs/fingerprintjs'
import React, { createContext, useContext, useEffect, useState } from 'react'

const TrackingContext = createContext<{
  fingerprint: string | null
}>({
  fingerprint: null,
})

export const TrackingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [fingerprint, setFingerprint] = useState<string | null>(null)
  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await Fingerprint2.getPromise({
        excludes: {
          screenResolution: true,
        },
      })
      const values = fp.map(component => component.value).join('')
      const fingerprint = Fingerprint2.x64hash128(values, 31)
      setFingerprint(fingerprint)
    }

    if (typeof window.requestIdleCallback !== 'undefined') {
      requestIdleCallback(function () {
        getFingerprint()
      })
    } else {
      setTimeout(function () {
        getFingerprint()
      }, 500)
    }
  }, [])

  return (
    <TrackingContext.Provider
      value={{
        fingerprint,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

export const useTracking = () => useContext(TrackingContext)
export default TrackingContext
