import { gql, useMutation, useQuery } from '@apollo/client'
import { GET_MEMBER_NOTIFICATION_SETTINGS, UPDATE_MEMBER_NOTIFICATION_SETTINGS } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { useCallback, useMemo } from 'react'
import { MemberPublicBriefProps, MemberPublicDetailProps } from 'src/types/member'
import { NIL as NIL_UUID } from 'uuid'

export type MemberOrderType = 'courseCount' | 'followerCount'
export type MemberPublicsQueryOptions = {
  limit?: number
  offset?: number
  isDetailShown?: boolean
  instructorType?: 'course' | 'all' | 'hasProduct' | 'authorOnly'
  courseCategorySlug?: string | null
  memberId?: string
  memberIds?: string[]
  search?: string | null
  orderBy?: MemberOrderType
}
export const mapMemberPublicsQueryOptionsToGqlVariables = (
  options: MemberPublicsQueryOptions,
  params: { appId: string },
) => {
  const variables: types.GET_MEMBER_PUBLICSVariables = {
    condition: {
      app_id: { _eq: params.appId },
      is_private: { _eq: false },
      course_instructors: options.courseCategorySlug
        ? { course: { course_categories: { category: { slug: { _eq: options.courseCategorySlug } } } } }
        : undefined,
      id: options?.memberId ? { _eq: options.memberId } : options?.memberIds ? { _in: options.memberIds } : undefined,
      _and: [
        {
          _or: [
            ...(options?.instructorType === 'all' || options?.instructorType === 'authorOnly'
              ? [{ role: { _eq: 'author' } }]
              : []),
            ...(options?.instructorType === 'hasProduct' || options?.instructorType === 'all'
              ? [
                  {
                    product_owners: {
                      product: {
                        deleted_at: { _is_null: true },
                        online_at: { _lte: 'now()' },
                        _or: [{ offline_at: { _gte: 'now()' } }, { offline_at: { _is_null: true } }],
                      },
                    },
                  },
                ]
              : []),
            ...(options?.instructorType === 'course'
              ? [
                  {
                    course_instructors: {
                      course: {
                        published_at: { _is_null: false },
                        _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }],
                        is_private: { _eq: false },
                      },
                    },
                  },
                ]
              : []),
          ],
        },
        ...(options?.search
          ? [
              {
                _or: [{ name: { _ilike: `%${options.search}%` } }, { display_name: { _ilike: `%${options.search}%` } }],
              },
            ]
          : []),
      ],
    },
    limit: options?.limit,
    offset: options?.offset,
    isDetailShown: options?.isDetailShown ?? false,
    orderBy:
      options?.orderBy === 'courseCount'
        ? [{ courses_aggregate: { count: 'desc' as types.order_by } }]
        : options?.orderBy === 'followerCount'
        ? [{ member_followers_aggregate: { count: 'desc' as types.order_by } }]
        : undefined,
  }

  return variables
}

export const formatGqlMemberPublics = (
  data: types.GET_MEMBER_PUBLICS | undefined,
  options?: { defaultAvatarUrl?: string | null; isDetailShown?: boolean },
): MemberPublicBriefProps[] | MemberPublicDetailProps[] => {
  const defaultAvatarUrl = options?.defaultAvatarUrl ?? null
  const isDetailShown = options?.isDetailShown ?? false

  return isDetailShown
    ? (data?.member_public || []).map(member => ({
        id: member.id as string,
        name: member.name,
        username: member.username,
        displayName: member.display_name || member.name || member.username,
        abstract: member.abstract,
        avatarUrl: member.avatar_url || defaultAvatarUrl,
        title: member.title,
        age: member.age_year,
        profileData: member.profile_data,
        coursesPurchaseCount: member.course_possessions_aggregate?.aggregate?.count || 0,
        courseCount: member.course_instructors_aggregate?.aggregate?.count || 0,
        totalProductCount: member.product_owners_aggregate?.aggregate?.count || 0,
        followerCount: member.member_followers_aggregate?.aggregate?.count || 0,
        isPrivate: member.is_private,
      }))
    : (data?.member_public || []).map(member => ({
        id: member.id as string,
        name: member.name,
        username: member.username,
        displayName: member.display_name || member.name || member.username,
        abstract: member.abstract,
        avatarUrl: member.avatar_url || defaultAvatarUrl,
        title: member.title,
        isPrivate: member.is_private,
      }))
}

export const useMemberNotificationSettings = (memberId: string | null) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_NOTIFICATION_SETTINGS,
    types.GET_MEMBER_NOTIFICATION_SETTINGSVariables
  >(GET_MEMBER_NOTIFICATION_SETTINGS, {
    skip: !memberId,
    variables: {
      memberId: memberId ?? '',
    },
  })

  const memberNotificationSettings: Record<string, boolean> = useMemo(
    () => (data?.member_by_pk ? data.member_by_pk.notification_settings : {}),
    [data?.member_by_pk],
  )
  return {
    loadingMemberNotificationSettings: loading,
    errorMemberNotificationSettings: error,
    memberNotificationSettings,
    refetchMemberNotificationSettings: refetch,
  }
}

export const useMemberNotificationMutation = () => {
  const [updateMemberNotificationSettingsHandler] = useMutation<
    types.UPDATE_MEMBER_NOTIFICATION_SETTINGS,
    types.UPDATE_MEMBER_NOTIFICATION_SETTINGSVariables
  >(UPDATE_MEMBER_NOTIFICATION_SETTINGS)
  const updateMemberNotificationSettings = useCallback(
    (memberId: string, settings: { [key: string]: boolean }) => {
      return updateMemberNotificationSettingsHandler({
        variables: {
          memberId,
          append: {
            notification_settings: settings,
          },
        },
      })
    },
    [updateMemberNotificationSettingsHandler],
  )

  return {
    updateMemberNotificationSettings,
  }
}

export const useMemberMetadataPathData = (memberId: string | null, metadataPath: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_PATH_METADATA,
    types.GET_MEMBER_PATH_METADATAVariables
  >(
    gql`
      query GET_MEMBER_PATH_METADATA($memberId: String!, $metadataPath: String!) {
        member_by_pk(id: $memberId) {
          id
          metadata(path: $metadataPath)
        }
      }
    `,
    {
      skip: !memberId || memberId === NIL_UUID || !metadataPath,
      variables: {
        memberId: memberId ?? '',
        metadataPath,
      },
    },
  )

  const memberMetadataPathData: any | null = useMemo(() => {
    return data?.member_by_pk?.metadata ?? null
  }, [data?.member_by_pk?.metadata])

  return {
    loadingMemberMetadataPathData: loading,
    errorMemberMetadataPathData: error,
    memberMetadataPathData,
    refetchMemberMetadataPathData: refetch,
  }
}
