'use client'

import React from 'react'
import { fetchAppByAppId } from 'src/utils/app'
import { fetchAppIdByHost } from 'src/utils/appId'
import { SWRConfig } from 'swr'

const SWRWrapper: React.FC<React.PropsWithChildren<{ fallback?: { [key: string]: any } }>> = ({
  fallback,
  children,
}) => {
  return (
    <SWRConfig
      value={{
        fetcher: (key: string) => {
          const [resource, param] = key.split('?')

          switch (resource) {
            case 'appId':
              const host = typeof window === 'undefined' ? undefined : window.location.host
              return fetchAppIdByHost(host)
            case 'app':
              return fetchAppByAppId(param)
          }
        },
        fallback,
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SWRWrapper
