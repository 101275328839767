'use client'

import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { useApp } from 'src/contexts/AppContext'
import { SeoOptionsProps } from 'src/types/shared'
import StructuredData from '../common/StructuredData'

const isDev =
  process.env.NODE_ENV === 'development' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const SEOHead: React.FC<SeoOptionsProps> = ({ seoOptions }) => {
  const router = useRouter()
  const { name: appName, frontDomain, settings } = useApp()

  const siteName = settings['seo.og_site_name'] || appName
  const siteTitle = `${isDev ? '[測試站]' : ''}${seoOptions?.title ? `${seoOptions?.title} | ${siteName}` : siteName}`
  const pageUrl = frontDomain ? `https://${frontDomain}${seoOptions?.path || router.asPath}` : null

  return (
    <Head>
      {seoOptions?.title && (
        <>
          <title>{siteTitle}</title>
          <meta property="og:title" content={siteTitle || ''} key="og:title" />
        </>
      )}
      {pageUrl && (
        <>
          <link rel="canonical" href={pageUrl} key="canonical" />
          <meta property="og:url" content={pageUrl} key="og:url" />
        </>
      )}
      {seoOptions?.description && (
        <>
          <meta name="description" content={seoOptions.description} key="description" />
          <meta property="og:description" content={seoOptions.description || ''} key="og:description" />
        </>
      )}
      {seoOptions?.imageUrl && <meta property="og:image" content={seoOptions.imageUrl} key="og:image" />}
    </Head>
  )
}

const SEOWrapper: React.FC = () => {
  const app = useApp()
  const siteName = app.settings['seo.og_site_name'] || app.name
  const homepageTile = app.settings['seo.og_title'] || app.name
  const defaultSiteImage = app.settings['seo.og_image'] || app.faviconUrl || app.logoUrl
  const siteDescription = app.settings['seo.og_description'] || app.description

  const googleSiteVerification = app.settings['verification.google_site_verification']

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        {siteName && <meta property="og:site_name" content={siteName} />}
        {homepageTile && (
          <>
            <title>{homepageTile}</title>
            <meta property="og:title" content={homepageTile} key="og:title" />
          </>
        )}
        {app?.faviconUrl && <link rel="icon" href={app.faviconUrl} />}
        {defaultSiteImage && <meta property="og:image" content={defaultSiteImage} key="og:image" />}
        {siteDescription && (
          <>
            <meta name="description" content={siteDescription} key="description" />
            <meta property="og:description" content={siteDescription || ''} key="og:description" />
          </>
        )}
        <meta property="og:type" content="website" key="og:type" />
        {app?.settings?.['social_api.facebook_id'] && (
          <meta property="fb:app_id" content={app.settings['social_api.facebook_id']} />
        )}
        {app?.frontDomain && <meta property="og:url" content={`https://${app.frontDomain}`} key="og:url" />}
        {googleSiteVerification && <meta name="google-site-verification" content={googleSiteVerification} />}
      </Head>

      {app.name && (
        <>
          <StructuredData
            data={{
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: `${app.name}`,
              logo: `${app.logoUrl}`,
              url: `https://${app.frontDomain}`,
            }}
          />
          <StructuredData
            data={{
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: app.name,
              url: `https://${app.frontDomain}/`,
              potentialAction: [
                {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: `https://${app.frontDomain}/search?q={search_term_string}`,
                  },
                  ['query-input' as any]: 'required name=search_term_string',
                },
              ],
            }}
          />
        </>
      )}
    </>
  )
}

export default SEOWrapper
