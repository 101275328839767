import { sessionStore } from '@havppen/utils/src/storageFactory'
import { httpBatchLink, HTTPHeaders } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import storageKeys from 'src/configs/storageKeys'
import { AppRouter } from 'src/server/trpc'
import { ApiClientParams } from 'src/types/shared'
import SuperJson from 'superjson'

export const trpc = createTRPCReact<AppRouter>()

export const initializeTrpcClient = (options: ApiClientParams = {}) => {
  return trpc.createClient({
    transformer: SuperJson,
    links: [
      httpBatchLink({
        url: '/api/trpc',
        headers() {
          const appId = options.appId || ''
          const headers: HTTPHeaders = {
            'Content-Type': 'application/json',
            'x-app-id': appId,
          }
          const authToken = sessionStore.getItem(storageKeys.AUTH_TOKEN)
          if (authToken) {
            headers['authorization'] = authToken
          }

          return headers
        },
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: 'include',
          })
        },
      }),
    ],
  })
}
