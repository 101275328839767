import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, Operation, split } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { sessionStore } from '@havppen/utils/src/storageFactory'
import { captureException } from '@sentry/nextjs'
import merge from 'deepmerge'
import { print } from 'graphql'
import jwtDecode from 'jwt-decode'
import { isEqual } from 'lodash-es'
import storageKeys from 'src/configs/storageKeys'
import { ApiClientParams } from 'src/types/shared'
import { NIL as NIL_UUID } from 'uuid'
import { onCaptureInvalidJWT } from './apollo.client'

const retryErrorMessages = ['Failed to fetch', 'Load failed', 'Unexpected token < in JSON at position 0']

const batchHttpLink = new BatchHttpLink({
  uri: `https://${process.env.NEXT_PUBLIC_HASURA_HOST}/v1/graphql`,
  headers: { batch: 'true' },
})
const httpLink = createHttpLink({ uri: `https://${process.env.NEXT_PUBLIC_HASURA_HOST}/v1/graphql` })
const httpLinks = split(operation => operation.getContext().important === true, httpLink, batchHttpLink)

export const createApolloLink = (
  authOptions: ApiClientParams,
  options?: {
    onJWTInvalid?: (error: any) => void
    onCaptureGraphQLError?: (error: any, operation: Operation) => void
    onCaptureNetworkError?: (error: NetworkError) => void
  },
) => {
  const appId = authOptions?.appId || ''
  const authLink = setContext((_, { headers }) => {
    const authToken = sessionStore.getItem(storageKeys.AUTH_TOKEN)
    return {
      headers: {
        ...headers,
        ...(authToken
          ? { authorization: `Bearer ${authToken}` }
          : {
              'x-hasura-app-id': appId,
              'x-hasura-user-id': NIL_UUID,
              'x-hasura-role': 'anonymous',
            }),
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(error => {
        console.error(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}, ${error.extensions?.code}`,
          error.extensions,
        )

        if (error?.extensions.code !== 'invalid-jwt') {
          options?.onCaptureGraphQLError?.(error.originalError, operation)
        }
      })

    if (networkError) {
      console.error('[Network error]:', networkError)
      options?.onCaptureNetworkError?.(networkError)
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      if (error.message && retryErrorMessages.includes(error.message)) {
        if (count > 5) options?.onCaptureGraphQLError?.(error, operation)
        return count <= 5
      }

      const isQuery =
        operation &&
        operation.query &&
        operation.query.definitions &&
        Array.isArray(operation.query.definitions) &&
        operation.query.definitions.some(def => def.kind === 'OperationDefinition' && def.operation === 'query')
      if (isQuery) {
        if (count > 3) options?.onCaptureGraphQLError?.(error, operation)
        return count <= 3
      }

      return false
    },
  })

  return ApolloLink.from([errorLink, retryLink, authLink, httpLinks])
}

export const captureGraphQLError = (error: any, operation: Operation) => {
  const operationName = operation.operationName
  const operationVariables = operation.variables
  const definition = operation.query.definitions.find(q => q.kind === 'OperationDefinition')
  const operationQuery = definition ? definition.loc?.source.body ?? print(definition) : null

  captureException(error, scope => {
    scope.setTag('kind', operationName)
    scope.setExtra('variables', operationVariables)
    if (operationQuery) scope.setExtra('query', operationQuery)

    const authToken = sessionStore.getItem(storageKeys.AUTH_TOKEN)
    if (authToken) {
      const payload = jwtDecode(authToken) as any
      scope.setExtra('memberId', payload.sub)
    }

    if (error.path) {
      scope.addBreadcrumb({
        category: 'query-path',
        message: error.path.join(' > '),
        level: 'debug',
      })
    }

    return scope
  })
}

export const createApolloClient = (options: ApiClientParams = {}) => {
  const apolloClient = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createApolloLink(options, {
      onCaptureGraphQLError: captureGraphQLError,
      onJWTInvalid: onCaptureInvalidJWT,
    }),
    cache: new InMemoryCache(),
  })
  return apolloClient
}

export const initializeApolloClient = (
  initialState: { [key: string]: any } | null = null,
  options?: ApiClientParams,
) => {
  const apolloClient = createApolloClient(options)

  if (initialState) {
    const existingCache = apolloClient.extract()
    const cache = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    })

    apolloClient.cache.restore(cache)
  }

  return apolloClient
}
