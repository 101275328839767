import { createIntl, createIntlCache } from '@formatjs/intl'
import defaultTranslationMessages from '@havppen/translation/locales/zh-tw.json'
import { DEFAULT_LOCAL } from 'src/libs/languageLib'

export const createTranslationIntl = (
  language?: string | null,
  options?: {
    timeZone?: string
  },
) => {
  let messages = defaultTranslationMessages
  const defaultLocale = (language ?? DEFAULT_LOCAL).toLowerCase()
  try {
    if (defaultLocale) {
      messages = require(`@havppen/translation/locales/${defaultLocale}.json`)
    }
  } catch {}
  const cache = createIntlCache()
  const intl = createIntl({ locale: defaultLocale, messages, ...options }, cache)
  intl.onError = error => {}

  return intl
}
