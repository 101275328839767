'use client'

import { MemberRoleType } from '@havppen/types/src/member'
import { ResponseCode } from '@havppen/types/src/shared'
import { localStore, sessionStore } from '@havppen/utils/src/storageFactory'
import { Liff } from '@line/liff/exports'
import jwtDecode from 'jwt-decode'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import storageKeys from 'src/configs/storageKeys'
import AuthChannelClient from 'src/helpers/auth.channel.client'
import { trpc } from 'src/helpers/trpc'
import { SocialProfileProps } from 'src/types/shared'
import useSWRMutation from 'swr/mutation'
import { NIL as NIL_UUID } from 'uuid'

type ApiRequestFetcherProps = (
  path: string,
  params: { arg: { data?: any; headers?: { [key: string]: string }; signal?: AbortSignal } },
) => Promise<ApiResponse>

type CredentialApiRequestFetcherProps = (
  path: string,
  params: { arg: { data?: any; authToken?: string | null; signal?: AbortSignal } },
) => Promise<ApiResponse>

const apiEndpoint = '/api/v1'
const getApiRequestFetcher: (appId: string, authToken: string | null) => ApiRequestFetcherProps =
  (appId, authToken) => async (path, params) => {
    const { data, signal } = params.arg

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...(params.arg.headers ?? {}),
    }
    if (authToken) headers['Authorization'] = `Bearer ${authToken}`

    const response = await fetch(`${apiEndpoint}${path}`, {
      method: 'POST',
      body: JSON.stringify({ appId, ...data }),
      headers,
      signal,
    })
    return response.json()
  }

const getCredentialApiRequestFetcher: (appId: string) => CredentialApiRequestFetcherProps =
  appId => async (path, params) => {
    const data = params.arg?.data || {}
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    }
    if (params.arg?.authToken) headers['Authorization'] = `Bearer ${params.arg.authToken}`
    if (!navigator.cookieEnabled || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      headers['cookie-unsupported'] = 'true'

      const sessionJwt = localStore.getItem('session-jwt')
      if (sessionJwt) headers['session-jwt'] = sessionJwt
    }

    try {
      const response = await fetch(`${apiEndpoint}${path}`, {
        method: 'POST',
        body: JSON.stringify({ appId, ...data }),
        headers,
        signal: params.arg?.signal,
      })

      const sessionJwt = response.headers.get('set-session-jwt')
      if (sessionJwt) {
        localStore.setItem('session-jwt', sessionJwt)
      }

      return response.json()
    } catch (error) {
      console.error(error)
      return { code: 'E_REQUEST_ERROR', message: error.message, result: null } as ApiResponse
    }
  }

type ApiResponse = {
  code: ResponseCode
  message: string
  result: any
}

type AuthProps = {
  isAuthenticating: boolean
  isAuthenticated: boolean
  currentMemberRole: MemberRoleType
  currentMemberId: string
  authToken: string | null
  currentMember: {
    id: string | null
    name: string | null
    displayName: string | null
    username: string | null
    email: string | null
    avatarUrl: string | null
    confirmedEmailAt: Date | null
    isPrivate: boolean | null
  } | null
  socialProfile?: SocialProfileProps | null
  apiEndpoint: string | null
  liff?: Liff | null
  sendApiRequest: ApiRequestFetcherProps
  setAuthToken?: (token: string | null) => void
  setSocialProfile?: React.Dispatch<React.SetStateAction<SocialProfileProps | null>>
  refreshAuthToken?: () => void
  register?: (data: {
    username: string
    email: string
    password: string
    referralCode: string | undefined
  }) => Promise<{ isEmailConfirmed: boolean } | undefined>
  login?: (data: { account: string; password: string }) => Promise<void>
  socialLogin?: (params: {
    provider: string
    providerToken: any
    referralCode?: string | null
  }) => Promise<{ isNewMember: boolean } | undefined>
  socialTokenLogin?: (params: {
    provider: string
    authToken: string
    idToken?: string
    referralCode?: string | null
  }) => Promise<void>
  logout?: () => Promise<void>
  setLiff?: React.Dispatch<React.SetStateAction<Liff | null>>
}

export type AuthPayloadProps = {
  sub: string
  appId: string
  memberId: string
  name: string | null
  displayName: string | null
  username: string | null
  email: string | null
  avatarUrl: string | null
  confirmedEmailAt: string | null
  isPrivate: boolean | null
  role: MemberRoleType
  exp: number
}

const defaultAuthProps: AuthProps = {
  isAuthenticating: true,
  isAuthenticated: false,
  currentMemberRole: 'anonymous',
  currentMemberId: NIL_UUID,
  authToken: null,
  currentMember: null,
  socialProfile: null,
  apiEndpoint: null,
  liff: null,
  sendApiRequest: getApiRequestFetcher('', null),
}

const AuthContext = createContext<AuthProps>(defaultAuthProps)

export const AuthProvider: React.FC<React.PropsWithChildren<{ appId?: string }>> = ({ appId = '', children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [authToken, setAuthToken] = useState<string | null>(null)
  const [socialProfile, setSocialProfile] = useState<SocialProfileProps | null>(null)
  useEffect(() => {
    setSocialProfile(null)
  }, [])

  const updateAuthToken = useCallback(
    (token: string | null) => {
      if (token) {
        sessionStore.setItem(storageKeys.AUTH_TOKEN, token)
      } else {
        sessionStore.removeItem(storageKeys.AUTH_TOKEN)
      }
      setAuthToken(token)
    },
    [setAuthToken],
  )

  const { mutate: refreshAuthToken } = trpc.auth.refreshAuthToken.useMutation({
    onSuccess: ({ code, result }) => {
      if (code === 'SUCCESS' && result && result.authToken) {
        updateAuthToken(result.authToken)
      } else {
        updateAuthToken(null)
      }
      setIsAuthenticating(false)
    },
  })
  useEffect(() => {
    refreshAuthToken()
  }, [refreshAuthToken])

  const payload = useMemo(() => {
    return authToken ? jwtDecode<AuthPayloadProps>(authToken) : null
  }, [authToken])

  const sendApiRequest = useMemo(() => getApiRequestFetcher(appId, authToken), [appId, authToken])
  const sendCredentialRequest = useMemo(() => getCredentialApiRequestFetcher(appId), [appId])

  const { trigger: register } = useSWRMutation('/auth/register', sendCredentialRequest)
  const { trigger: login } = useSWRMutation('/auth/general-login', sendCredentialRequest)
  const { trigger: socialLogin } = useSWRMutation('/auth/social-login', sendCredentialRequest)
  const { trigger: socialTokenLoginHandler } = useSWRMutation('/auth/social-token-login', sendCredentialRequest)
  const { trigger: logout } = useSWRMutation('/auth/logout', sendCredentialRequest, {
    onSuccess: () => {
      updateAuthToken(null)
    },
  })

  useEffect(() => {
    const authChannelClient = new AuthChannelClient()
    authChannelClient.onLogout = () => {
      updateAuthToken(null)
    }
    authChannelClient.initialize()
  }, [updateAuthToken])

  const socialTokenLogin = useCallback(
    async (params: { provider: string; authToken: string; idToken?: string; referralCode?: string | null }) => {
      const response = await socialTokenLoginHandler({
        authToken,
        data: {
          provider: params.provider,
          authToken: params.authToken,
          referralCode: params.referralCode,
          idToken: params.idToken,
        },
      })
      if (!response) {
        return
      }

      const { code, result } = response
      if (code !== 'SUCCESS') {
        throw new Error(code)
      }

      if (result.profile && result.profile.provider === 'line' && !result.profile.email) {
        await logout()
        throw new Error('E_NO_SOCIAL_LINE_EMAIL')
      }

      if (result.authToken) {
        updateAuthToken(result.authToken)
      } else {
        setSocialProfile(result.profile)
      }

      const isRegister = result.isRegister as boolean
      const loginParams = { method: params.provider, member_id: result.memberId }
      if (isRegister) {
        window.sendEvent?.('sign_up', loginParams)
      } else {
        window.sendEvent?.('login', loginParams)
      }
    },
    [authToken, updateAuthToken, logout, socialTokenLoginHandler],
  )

  const [liff, setLiff] = useState<Liff | null>(null)
  useEffect(() => {
    if (!liff || isAuthenticating || authToken || window.location.pathname === '/liff-auth') {
      return
    }

    const idToken = liff.getIDToken()
    const accessToken = liff.getAccessToken()
    if (!idToken || !accessToken) {
      return
    }

    socialTokenLogin({
      provider: 'line',
      authToken: accessToken,
      idToken: idToken || undefined,
    }).catch(() => {})
  }, [liff, authToken, isAuthenticating, socialTokenLogin])

  return (
    <AuthContext.Provider
      value={{
        apiEndpoint,
        isAuthenticating,
        isAuthenticated: Boolean(authToken),
        currentMemberRole: payload?.role ?? 'anonymous',
        currentMemberId: payload?.sub ?? NIL_UUID,
        authToken: authToken || null,
        currentMember: payload
          ? {
              id: payload.sub,
              name: payload.name,
              displayName: payload.displayName || payload.name || payload.username,
              username: payload.username,
              email: payload.email,
              avatarUrl: payload.avatarUrl,
              confirmedEmailAt: payload.confirmedEmailAt ? new Date(payload.confirmedEmailAt) : null,
              isPrivate: payload.isPrivate,
            }
          : null,
        socialProfile,
        liff,
        setAuthToken: updateAuthToken,
        setLiff,
        setSocialProfile,
        refreshAuthToken,
        register: async ({ username, email, password, referralCode }) => {
          const trimmedUsername = username.trim()
          const trimmedEmail = email.trim()
          const response = await register({
            data: {
              username: trimmedUsername,
              email: trimmedEmail,
              password,
              referralCode,
            },
          })

          if (!response) {
            return
          }

          const { code, result } = response
          if (code !== 'SUCCESS') {
            throw new Error(code)
          } else if (result.authToken) {
            updateAuthToken(result.authToken)

            const loginParams = { method: 'Password', memberId: result.memberId }
            window.sendEvent?.('sign_up', loginParams)
            return { isEmailConfirmed: result.isEmailConfirmed }
          }
        },
        login: async ({ account, password }) => {
          const trimmedAccount = account.trim()
          const response = await login({ data: { account: trimmedAccount, password } })
          if (!response) {
            return
          }

          const { code, result } = response
          if (code !== 'SUCCESS') {
            throw new Error(code)
          } else if (result === null) {
            window.location.assign(`/auth/check-email?email=${trimmedAccount}`)
          } else if (result.authToken) {
            updateAuthToken(result.authToken)
            window.sendEvent?.('login', { method: 'Password', member_id: result.memberId })
          }
        },
        socialLogin: async (params: { provider: string; providerToken: any; referralCode?: string | null }) => {
          const redirectUri = `${window.location.origin}/oauth2`
          const response = await socialLogin({
            authToken,
            data: {
              provider: params.provider,
              providerToken: params.providerToken,
              redirectUri,
              referralCode: params.referralCode,
            },
          })
          if (!response) {
            return
          }

          const { code, result } = response
          if (code !== 'SUCCESS') {
            throw new Error(code)
          }

          if (result.authToken) {
            updateAuthToken(result.authToken)
          } else {
            setSocialProfile(result.profile)
          }

          const isRegister = result.isRegister as boolean
          const loginParams = { method: params.provider, member_id: result.memberId }
          if (isRegister) {
            window.sendEvent?.('sign_up', loginParams)
          } else {
            window.sendEvent?.('login', loginParams)
          }
          return { isNewMember: result.isNewMember }
        },
        socialTokenLogin,
        logout: async () => {
          liff?.logout()
          updateAuthToken(null)
          setSocialProfile(null)

          const authChannelClient = new AuthChannelClient()
          await authChannelClient.initialize(false)
          authChannelClient.postMessage('logout', {})
          localStore.removeItem('session-jwt')

          const response = await logout()
          if (!response) {
            return
          }

          if (response.code !== 'SUCCESS') {
            throw new Error(response.code)
          }
        },
        sendApiRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
