'use client'

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from './AuthContext'

const PreviewContext = createContext<{
  isAdmin: boolean
  isPreviewBarVisible: boolean
  isPreview: boolean
  isPreviewPurchased: boolean
  adminPathname: string
  setAdminPathname?: (pathname: string) => void
}>({
  isAdmin: false,
  isPreviewBarVisible: false,
  isPreview: false,
  isPreviewPurchased: false,
  adminPathname: '/',
})

export const PreviewProvider: React.FC<
  React.PropsWithChildren<{ isPreview?: boolean | null; isPreviewPurchased?: boolean | null }>
> = ({ isPreview, isPreviewPurchased, children }) => {
  const { currentMemberRole } = useAuth()
  const isCurrentPreview = isPreview ?? false
  const isAdmin = useMemo(() => ['admin', 'site-admin'].includes(currentMemberRole), [currentMemberRole])
  const isPreviewBarVisible = isCurrentPreview || isAdmin

  const [adminPathname, setAdminPathname] = useState<string>('/')

  return (
    <PreviewContext.Provider
      value={{
        isAdmin,
        isPreviewBarVisible,
        isPreview: isCurrentPreview,
        isPreviewPurchased: isPreviewPurchased ?? false,
        adminPathname,
        setAdminPathname,
      }}
    >
      {children}
    </PreviewContext.Provider>
  )
}

export const usePreviewAdminPathname = (pathname?: string | null) => {
  const { setAdminPathname } = useContext(PreviewContext)
  useEffect(() => {
    if (pathname) {
      setAdminPathname?.(pathname)
    }
  }, [pathname, setAdminPathname])
}

export default PreviewContext
