import { ShippingCvsOptionsProps } from '@havppen/types/src/shipping'
import React from 'react'

export const shippingStatusColorMapping = {
  PENDING: 'warning',
  RECEIVED: 'processing',
  DELIVERED: 'success',
  RETURNED: 'default',
}

export const getShippingTrackingUrl = (shippingMethod: string) => {
  switch (shippingMethod) {
    case 'cvs_711':
      return 'https://eservice.7-11.com.tw/e-tracking/search.aspx'
    case 'cvs_family':
      return 'https://ecfme.fme.com.tw/fmedcfpwebv2/index.aspx'
    case 'cvs_hilife':
      return 'https://www.hilife.com.tw/serviceInfo_search.aspx'
    case 'cvs_ok':
      return 'https://ecservice.okmart.com.tw/Tracking/Search'
    default:
      return null
  }
}

export const useCVSStoreSelect = (
  shippingMethod: string,
  options?: {
    shippingIndex?: number
    onReceiveCVSStoreData: (event: ShippingCvsOptionsProps) => void
  },
) => {
  const onStoreSelect = React.useCallback(() => {
    const shippingIndex = (options?.shippingIndex ?? 0).toString()
    const cvsSelectionBackUrl = encodeURIComponent(
      `${location.origin}/api/shipping/cvs/${shippingMethod}?${new URLSearchParams({
        callbackPath: '/callback/cvs',
        shippingIndex,
      })}`,
    )

    let cvsSelectionUrl
    switch (shippingMethod) {
      case 'cvs_711':
        cvsSelectionUrl = `https://emap.pcsc.com.tw/ecmap/default.aspx?eshopparid=935&eshopid=001&eshoppwd=presco123&tempvar=&sid=1&storecategory=3&showtype=1&storeid=&url=${cvsSelectionBackUrl}`
        break
      case 'cvs_ok':
        cvsSelectionUrl = `https://ecservice.okmart.com.tw/ECMapInquiry/ShowStore?userip=&cvsid=1592042616368&cvstemp=${cvsSelectionBackUrl}`
        break
      case 'cvs_hilife':
      case 'cvs_family':
        cvsSelectionUrl = `http://map.ezship.com.tw/ezship_map_new.jsp?rtURL=${cvsSelectionBackUrl}`
        break
      default:
        break
    }
    window.open(cvsSelectionUrl)
  }, [shippingMethod, options?.shippingIndex])

  React.useEffect(() => {
    const onReceiveMessage = (event: MessageEvent<ShippingCvsOptionsProps>) => {
      const { storeId, storeName, storeAddress } = event.data
      if (storeId && storeName && storeAddress) {
        options?.onReceiveCVSStoreData(event.data)
      }
    }

    window.addEventListener('message', onReceiveMessage)
    return () => {
      window.removeEventListener('message', onReceiveMessage)
    }
  }, [options])

  return { onStoreSelect }
}
