'use client'

import Script from 'next/script'
import React, { useEffect } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'

const ThirdPartyLibrary: React.FC = () => {
  const { settings } = useApp()
  const { setLiff } = useAuth()
  const facebookId = settings['social_api.facebook_id']
  const adsenseId = settings['ad.google_adsense_id']
  const liffId = settings['social_api.line_liff_id']

  useEffect(() => {
    const initLiff = async () => {
      if (!liffId) {
        return
      }

      const liff = (await import('@line/liff')).default
      liff.init({ liffId })
      setLiff?.(liff)
    }

    initLiff()
  }, [liffId, setLiff])

  return (
    <>
      <Script src="https://cdn.jsdelivr.net/npm/intersection-observer@0.12.2/intersection-observer.min.js" />

      {facebookId && (
        <Script
          id="facebook-sdk"
          src="https://connect.facebook.net/en_US/sdk.js"
          onLoad={() => {
            window.FB.init({
              appId: facebookId,
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v16.0',
            })
          }}
        />
      )}

      {adsenseId && (
        <Script
          key="adsense"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          async
          strategy="lazyOnload"
        />
      )}
    </>
  )
}

export default ThirdPartyLibrary
