import { gql, useMutation, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { MemberRoleType } from '@havppen/types/src/member'
import { localStore } from '@havppen/utils/src/storageFactory'
import { SocialProviderType } from '@havppen/validates/src/member'
import { message } from 'antd'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useApp } from 'src/contexts/AppContext'
import { getSignUpReferrerCode } from 'src/helpers/helper.client'
import { isCurrentRoleEnabled } from 'src/utils/permission'
import { useAuth } from '../contexts/AuthContext'

export const socialLoginAppSettingMapping = {
  facebook: 'social_api.facebook_id',
  google: 'social_api.google_client_id',
  line: 'social_api.line_client_id',
  quickper: 'social_api.quickper_sso_domain',
}

export const getSocialLoginAppSettingKey = (provider: SocialProviderType) => {
  return socialLoginAppSettingMapping[provider as keyof typeof socialLoginAppSettingMapping] as string | undefined
}

export const useIsCurrentRoleEnabled = (role?: MemberRoleType) => {
  const { currentMemberRole } = useAuth()

  return isCurrentRoleEnabled(currentMemberRole, role)
}

export const useSignUpReferralCode = () => {
  const searchParams = useSearchParams()
  const referralCode = searchParams?.get('referrer')

  useEffect(() => {
    if (referralCode) {
      localStore.setItem('signUpReferralCode', referralCode)
    }
  }, [referralCode])
}

export const useIsPasswordLoginEnabled = () => {
  const { settings } = useApp()
  const isPasswordLoginEnabled = useMemo(
    () => process.env.NODE_ENV !== 'production' || settings['auth.is_password_login_enabled'] !== 'false',
    [settings],
  )

  return isPasswordLoginEnabled
}

export const useIsSocialLoginEnabled = () => {
  const { settings } = useApp()
  const isSocialLoginEnabled = useMemo(() => {
    const socialLoginId =
      settings[socialLoginAppSettingMapping.facebook] ||
      settings[socialLoginAppSettingMapping.google] ||
      settings[socialLoginAppSettingMapping.line] ||
      settings[socialLoginAppSettingMapping.quickper]

    return Boolean(socialLoginId)
  }, [settings])

  return isSocialLoginEnabled
}

export const useSocialLoginUrl = (provider: SocialProviderType, redirectPath?: string | null) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { settings, frontDomain } = useApp()

  const socialLoginUrl = useMemo(() => {
    const appSettingKey = getSocialLoginAppSettingKey(provider)
    const clientId = appSettingKey ? settings[appSettingKey] : undefined
    if (!clientId) {
      return null
    }

    const origin = (typeof window !== 'undefined' ? window.location.origin : '') || `https://${frontDomain}`
    const redirect = redirectPath || pathname || '/'
    const redirectUri = `${origin}/oauth2`
    const referralCode = getSignUpReferrerCode(searchParams)

    let socialLoginEndpoint: string | null = null
    let socialLoginSearchParams = new URLSearchParams()
    switch (provider) {
      case 'facebook':
        socialLoginEndpoint = 'https://www.facebook.com/v6.0/dialog/oauth'
        socialLoginSearchParams = new URLSearchParams({
          client_id: clientId,
          redirect_uri: redirectUri,
          scope: 'public_profile,email',
          response_type: 'token',
          state: JSON.stringify({
            provider,
            redirect: encodeURIComponent(redirect),
            referralCode: referralCode ? encodeURIComponent(referralCode) : undefined,
          }),
        })
        break
      case 'google':
        socialLoginEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
        socialLoginSearchParams = new URLSearchParams({
          client_id: clientId,
          redirect_uri: redirectUri,
          scope: 'openid profile email',
          include_granted_scopes: 'true',
          response_type: 'token',
          state: JSON.stringify({
            provider,
            referralCode: referralCode ? encodeURIComponent(referralCode) : undefined,
            // redirect: encodeURIComponent(redirect),
          }),
        })
        break
      case 'line':
        socialLoginEndpoint = 'https://access.line.me/oauth2/v2.1/authorize'
        socialLoginSearchParams = new URLSearchParams({
          client_id: clientId,
          redirect_uri: redirectUri,
          scope: 'profile openid email',
          response_type: 'code',
          bot_prompt: 'aggressive',
          state: JSON.stringify({
            provider,
            redirect: encodeURIComponent(redirect),
            referralCode: referralCode ? encodeURIComponent(referralCode) : undefined,
            nonce: (Math.random() * 10000).toFixed(0),
          }),
        })
        break
      case 'quickper': {
        const quickperSSODomain = settings['social_api.quickper_sso_domain']
        socialLoginEndpoint = `https://${quickperSSODomain}/auth/tp/login`
        socialLoginSearchParams = new URLSearchParams({
          redirect_uri: redirectUri,
          state: JSON.stringify({
            provider,
            redirect: encodeURIComponent(redirect),
            referralCode: referralCode ? encodeURIComponent(referralCode) : undefined,
          }),
        })
        break
      }
    }

    if (!socialLoginEndpoint) {
      return null
    }

    const uri = new URL(socialLoginEndpoint)
    uri.search = socialLoginSearchParams.toString()
    return uri.toString()
  }, [provider, frontDomain, redirectPath, pathname, searchParams, settings])

  return socialLoginUrl
}

const getPathParams = () => {
  const hash = window.location.hash
  const search = window.location.search
  const params = new URLSearchParams(hash ? '?' + hash.replace('#', '') : search)
  const accessToken = params.get('access_token') ?? params.get('code') ?? params.get('auth_code')
  const backUrl = params.get('back_url') ?? params.get('direct_to_course')
  const referrer = getSignUpReferrerCode(params)

  let state: Record<string, any> = {}
  try {
    state = JSON.parse(params.get('state') || '{}')
  } catch {
    state = {
      provider: params.has('liffClientId') ? 'line' : '',
      redirect: params.get('liffRedirectUri') || '',
      referralCode: undefined,
    }
  }

  return {
    params,
    state,
    accessToken,
    referrer,
    backUrl,
  }
}

type SocialLoginStateProps = {
  provider: string
  redirect: string
  referralCode: string | undefined
}

export const useOauth2Redirect = () => {
  const intl = useIntl()
  const { isAuthenticating, liff, socialLogin, socialTokenLogin } = useAuth()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const router = useRouter()
  const [messageApi, messageContextHolder] = message.useMessage()

  useEffect(() => {
    const { accessToken, referrer, backUrl, params, state } = getPathParams()

    const socialLoginData = {
      provider: state.provider,
      providerToken: accessToken,
      referralCode: referrer,
    }
    if (window.opener) {
      window.opener.postMessage({ type: 'socialLogin', ...socialLoginData }, window.location.origin)
      window.close()
      return
    }

    if (params.get('liff.state') || params.get('redirect_uri')) {
      // Liff login
      return
    }

    if (isAuthenticating || isLoggedIn || !state.provider) return
    setIsLoggedIn(true)

    const redirectPath = decodeURIComponent(state.redirect || '')
    const path = redirectPath === '' ? '/' : redirectPath
    const redirectToPath = () => {
      if (backUrl) {
        router.push(`${path}?backUrl=${encodeURIComponent(backUrl)}`)
      } else {
        router.push(path)
      }
    }
    if (!accessToken) {
      redirectToPath()
      return
    }

    socialLogin?.(socialLoginData)
      .then(() => {
        localStore.removeItem('signUpReferralCode')
        messageApi.success('登入成功')

        redirectToPath()
      })
      .catch(setError)
  }, [isAuthenticating, isLoggedIn, socialLogin, messageApi, router])

  useEffect(() => {
    // Liff login
    const params = new URLSearchParams(window.location.search)
    const redirectPath = params.get('redirect_uri') ?? '/'
    if (isLoggedIn || !liff) return

    setIsLoggedIn(true)
    const idToken = liff.getIDToken()
    if (!idToken) {
      return
    }

    socialTokenLogin?.({
      provider: 'line',
      authToken: idToken,
      idToken: idToken,
    })
      .then(() => {
        messageApi.success('登入成功')
        router.push(redirectPath)
      })
      .catch(setError)
  }, [isLoggedIn, liff, messageApi, router, socialTokenLogin])

  useEffect(() => {
    if (error) {
      messageApi.error(intl.formatMessage({ id: `error.${error.message}`, defaultMessage: error.message }), 5000)

      setTimeout(() => {
        if (window.opener) {
          window.close()
        } else {
          router.push('/')
        }
      }, 5000)
    }
  }, [error, intl, messageApi, router])

  return { messageContextHolder }
}

export const useIsSocialLoginProviderActive = (provider: SocialProviderType) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MEMBER_SOCIAL_LOGIN,
    types.GET_MEMBER_SOCIAL_LOGINVariables
  >(
    gql`
      query GET_MEMBER_SOCIAL_LOGIN($provider: String) {
        member_social_login(where: { provider: { _eq: $provider } }) {
          provider
        }
      }
    `,
    { variables: { provider } },
  )

  return {
    loadingIsSocialLoginProviderActive: loading,
    errorIsSocialLoginProviderActive: error,
    isSocialLoginProviderActive: data ? data.member_social_login.length > 0 : false,
    refetchIsSocialLoginProviderActive: refetch,
  }
}

export const useSocialLoginMutation = (provider: SocialProviderType) => {
  const [deleteMemberSocialLoginHandler] = useMutation<
    types.DELETE_MEMBER_SOCIAL_LOGIN,
    types.DELETE_MEMBER_SOCIAL_LOGINVariables
  >(gql`
    mutation DELETE_MEMBER_SOCIAL_LOGIN($provider: String!) {
      delete_member_social_login(where: { provider: { _eq: $provider } }) {
        affected_rows
      }
    }
  `)
  const deleteMemberSocialLogin = () => {
    return deleteMemberSocialLoginHandler({
      variables: { provider },
    })
  }

  return { deleteMemberSocialLogin }
}

const permissionPagePaths = ['/courses/mine', '/my-collection', '/dashboard']
export const useAuthRelatedPageBeforePopSate = () => {
  const pathname = usePathname()
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (!isAuthenticated && permissionPagePaths.includes(pathname ?? '/')) {
      window.location.href = '/'
    }
  }, [isAuthenticated, pathname])
}

export const useLoginOnlyRedirect = () => {
  const { isAuthenticated } = useAuth()

  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  const router = useRouter()
  const searchParams = useSearchParams()
  useEffect(() => {
    if (isAuthenticated && !isRendered) {
      const pathAfterLogin = searchParams?.get('afterLogin')
      router.push(pathAfterLogin ?? '/')
    }
  }, [isAuthenticated, isRendered, router, searchParams])
}

export const useLoginRequiredRedirect = (options?: {
  skip?: boolean
  beforeLoginPath?: string
  afterLoginPath?: string
}) => {
  const isLoginRequired = !options?.skip
  const beforeLoginPath = options?.beforeLoginPath ?? '/'
  const afterLoginPath = options?.afterLoginPath ?? '/'

  const router = useRouter()
  const pathname = usePathname()
  const { formatMessage } = useIntl()
  const { isAuthenticating, isAuthenticated } = useAuth()

  useEffect(() => {
    if (isLoginRequired && !isAuthenticating && !isAuthenticated) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('beforeLogin', beforeLoginPath)
      searchParams.set('afterLogin', pathname ?? afterLoginPath)

      router.push(`/auth/login?${searchParams.toString()}`)
      message.info(formatMessage({ id: 'auth.message.pleaseLoginFirst' }))
    }
  }, [
    formatMessage,
    isAuthenticated,
    isAuthenticating,
    isLoginRequired,
    beforeLoginPath,
    afterLoginPath,
    pathname,
    router,
  ])
}

export const usePermissionCheckRedirect = (targetRole: MemberRoleType, options?: { redirectPath?: string }) => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { isAuthenticating, isAuthenticated, currentMemberRole } = useAuth()
  const redirectPath = options?.redirectPath ?? '/'

  useEffect(() => {
    if (!isAuthenticating && isAuthenticated && !isCurrentRoleEnabled(currentMemberRole, targetRole)) {
      router.push(redirectPath)
      message.info(formatMessage({ id: 'auth.message.permissionDenied' }))
    }
  }, [formatMessage, isAuthenticated, isAuthenticating, currentMemberRole, targetRole, redirectPath, router])
}
