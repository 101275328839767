'use client'

import React from 'react'
import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
    --app-width: 100%;
    --card-max-width: 260px;
    --default-border-radius: 16px;

    ${props => {
      const theme = props.theme as any
      return css`
        --theme-primary-color: ${theme['primary-color']};
        --theme-secondary-color: ${theme['secondary-color']};
        --theme-link-color: ${theme['link-color']};
        --theme-success-color: ${theme['success-color']};
        --theme-warning-color: ${theme['warning-color']};
        --theme-error-color: ${theme['error-color']};
        --theme-info-color: ${theme['info-color']};

        --theme-title-color: ${theme['title-color']};
        --theme-title-hover-color: ${theme['title-color-hover']};
        --theme-highlight-text-color: ${theme['highlight-text-color']};
        --theme-text-color: ${theme['text-color']};
        --theme-default-text-color: ${theme['default-text-color']};
        --theme-default-text-color-light-solid: ${theme['default-text-color-light-solid']};
        --theme-primary-background-text-color: ${theme['primary-background-text-color']};
        --theme-primary-image-invert-filter: ${theme['primary-background-image-invert-filter']};

        --theme-border-color: ${theme['border-color']};
        --theme-background-color: ${theme['background-color']};
        --theme-background-image-url: ${theme['background-image-url']};
        --theme-component-background-color: ${theme['component-background-color']};
        --theme-component-text-color: ${theme['component-text-color']};
        --theme-box-shadow-color: ${theme['box-shadow-color']};
        --theme-box-shadow: 0 0 8px 0 ${theme['box-shadow-color']};

        --theme-footer-background-color: ${theme['footer-background-color']};
        --theme-footer-color: ${theme['footer-color']};
        --theme-header-background-color: ${theme['header-background-color']};
        --theme-header-color: ${theme['header-color']};

        --theme-input-background-color: ${theme['input-background-color']};
        --theme-auth-background-color: ${theme['auth-background-color'] ?? 'initial'};
        --theme-blur-image-background-color: ${theme['blur-image-background-color']};
        --theme-blur-image-background-url: ${theme['blur-image-background-url']
          ? `url(${theme['blur-image-background-url']})`
          : 'initial'};

        --theme-fundraising-color: ${theme['fundraising-color']};
        --theme-player-primary-color: ${theme['player-primary-color']};

        --theme-logo-url: ${theme['logo-url'] ? `url(${theme['logo-url']})` : 'initial'};
        --theme-favicon-url: ${theme['favicon-url'] ? `url(${theme['favicon-url']})` : 'initial'};
        --theme-logo-white-url: ${theme['logo-white-url'] ? `url(${theme['logo-white-url']})` : 'initial'};
      `
    }}
  }
  html,
  body {
    height: 100vh;
    height: var(--app-height);
    color: var(--theme-default-text-color);
    background-color: var(--theme-background-color, transparent);
    
    touch-action: manipulation;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--theme-title-color);
  }
  p {
    color: var(--theme-text-color);
  }
  a {
    text-decoration: none;
  }

  ::selection {
    color: var(--theme-highlight-text-color);
    background: var(--theme-primary-color);
  }
  input::placeholder {
    color: var(--theme-text-color) !important;
  }

  .text-highlight {
    color: var(--theme-primary-color);
  }
  .box-shadow {
    box-shadow: var(--theme-box-shadow);
  }
  .round {
    border-radius: var(--default-border-radius, 16px);
  }
  .round-small {
    border-radius: 10px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .position-relative {
    position: relative;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .w-100 {
    width: 100%;
  }

  hr {
    border-style: solid;
    border-color: ${props => (props.theme as any)['border-color']};
    border-top: 1px;
  }

  .uppload-container {
    .need-help-link {
      display: none;
    }
  }

  .ant-popover {
    position: fixed;
  }
  .ant-avatar {
    img {
      object-fit: cover;
    }
  }
`

const GlobalStyleElement: React.FC = () => {
  return <GlobalStyle />
}

export default GlobalStyleElement
