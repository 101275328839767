'use client'

import React, { createContext, useContext, useEffect, useState } from 'react'
import { ApplicationProps } from 'src/types/app'
import useSWR from 'swr'

const defaultAppContextProps: ApplicationProps = {
  id: process.env.NEXT_APP_ID || '',
  name: '',
  description: null,
  logoUrl: null,
  faviconUrl: null,
  defaultAvatarUrl: null,
  adminEmail: null,
  trialExpiredAt: null,
  themeColors: {},
  enabledModules: {},
  settings: {},
  frontDomain: null,
  backDomain: null,
  defaultLocale: null,
  defaultCurrency: 'TWD',
  isAppInitialized: true,
  layoutType: 'default',
  paymentType: 'custom',
  siteType: 'general',
  timezone: 'Asia/Taipei',
  navs: [],
}
export const AppContext = createContext<ApplicationProps>(defaultAppContextProps)

export const AppProvider: React.FC<
  React.PropsWithChildren<{
    appId: string | undefined
    defaultAppProps?: ApplicationProps | null
  }>
> = ({ appId, defaultAppProps, children }) => {
  const [showDefaultAppProps, setShowDefaultAppProps] = useState(Boolean(defaultAppProps))
  useEffect(() => {
    setShowDefaultAppProps(Boolean(defaultAppProps))
  }, [defaultAppProps])

  const { data: app } = useSWR<ApplicationProps | null>(showDefaultAppProps ? 'app' : `app?${appId}`, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    refreshInterval: 0,
  })

  return (
    <AppContext.Provider
      value={{
        ...defaultAppContextProps,
        id: appId || app?.id || '',
        ...app,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useApp = () => useContext(AppContext)
