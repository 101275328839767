import { useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { DiscountUnit } from '@havppen/types/src/discount'
import { ProductProps } from '@havppen/types/src/product'
import { useMemo } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import { GET_COUPON, GET_COUPONS, GET_COUPON_MEMBER_STATS } from 'src/gql/coupon'
import { mapProductFromGql } from 'src/types/product'
import { NIL as NIL_UUID } from 'uuid'

export type CouponProductProps = {
  id?: string
  productId: string
  product: ProductProps | null
}
export type CouponScope = 'all'
export type CouponProps = {
  id: string
  title: string
  abstract: string | null
  appId: string
  onlineAt: Date | null
  offlineAt: Date | null
  scope: CouponScope | null
  isReusable: boolean
  discountUnit: DiscountUnit
  discountAmount: number
  baseLimitAmount: number | null
  products: CouponProductProps[]
  excludedProducts: CouponProductProps[]
}
export type CouponStatus = 'available' | 'used' | 'expired'

export const useCouponStats = () => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_COUPON_MEMBER_STATS,
    types.GET_COUPON_MEMBER_STATSVariables
  >(GET_COUPON_MEMBER_STATS, {
    skip: currentMemberId === NIL_UUID,
    variables: { memberId: currentMemberId },
  })

  return {
    couponStats: {
      available: data?.available_coupon_member_aggregate.aggregate?.count || 0,
      used: data?.used_coupon_member_aggregate.aggregate?.count || 0,
      expired: data?.expired_coupon_member_aggregate.aggregate?.count || 0,
    },
    loadingCouponStats: loading,
    errorCouponStats: error,
    refetchCouponStats: refetch,
  }
}

export const useCoupons = (
  status: CouponStatus,
  options?: {
    skip?: boolean
  },
) => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<types.GET_COUPONS, types.GET_COUPONSVariables>(GET_COUPONS, {
    skip: options?.skip,
    variables: {
      condition: {
        coupon_codes: {
          coupon_members: {
            member_id: { _eq: currentMemberId },
            is_used: status === 'available' || status === 'used' ? { _eq: status === 'used' } : undefined,
          },
        },
        status: status === 'expired' ? { _eq: 'offline' } : status === 'available' ? { _eq: 'published' } : undefined,
        online_at: status === 'expired' ? { _gte: 'now()' } : undefined,
        _and:
          status === 'available' || status === 'used'
            ? [
                { _or: [{ online_at: { _is_null: true } }, { online_at: { _lte: 'now()' } }] },
                { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
              ]
            : undefined,
      },
    },
  })

  const coupons: CouponProps[] = useMemo(
    () =>
      (data?.coupon || []).map(coupon => ({
        id: coupon.id,
        title: coupon.title,
        abstract: coupon.abstract,
        appId: coupon.app_id,
        onlineAt: coupon.online_at ? new Date(coupon.online_at) : null,
        offlineAt: coupon.offline_at ? new Date(coupon.offline_at) : null,
        scope: coupon.scope,
        isReusable: coupon.is_reusable,
        discountUnit: coupon.discount_unit as DiscountUnit,
        discountAmount: coupon.discount_amount,
        baseLimitAmount: coupon.base_limit_amount,
        products: coupon.coupon_products
          .filter(coupon_product => !coupon_product.is_excluded)
          .map(coupon_product => ({
            id: coupon_product.id,
            productId: coupon_product.product_id,
            product: mapProductFromGql(coupon_product.product),
          })),
        excludedProducts: coupon.coupon_products
          .filter(coupon_product => coupon_product.is_excluded)
          .map(coupon_product => ({
            id: coupon_product.id,
            productId: coupon_product.product_id,
            product: mapProductFromGql(coupon_product.product),
          })),
      })),
    [data],
  )

  return {
    coupons,
    loadingCoupons: loading,
    errorCoupons: error,
    refetchCoupons: refetch,
  }
}

export const useCoupon = (couponId = NIL_UUID) => {
  const { loading, error, data, refetch } = useQuery<types.GET_COUPON, types.GET_COUPONVariables>(GET_COUPON, {
    variables: { couponId: couponId },
  })

  const coupon: CouponProps | null = useMemo(
    () =>
      loading || error || !data || !data.coupon_by_pk
        ? null
        : {
            id: data.coupon_by_pk.id,
            title: data.coupon_by_pk.title,
            abstract: data.coupon_by_pk.abstract,
            appId: data.coupon_by_pk.app_id,
            onlineAt: data.coupon_by_pk.online_at ? new Date(data.coupon_by_pk.online_at) : null,
            offlineAt: data.coupon_by_pk.offline_at ? new Date(data.coupon_by_pk.offline_at) : null,
            scope: data.coupon_by_pk.scope,
            isReusable: data.coupon_by_pk.is_reusable,
            discountUnit: data.coupon_by_pk.discount_unit as DiscountUnit,
            discountAmount: data.coupon_by_pk.discount_amount,
            baseLimitAmount: data.coupon_by_pk.base_limit_amount,
            products: data.coupon_by_pk.coupon_products
              .filter(coupon_product => !coupon_product.is_excluded)
              .map(coupon_product => ({
                id: coupon_product.id,
                productId: coupon_product.product_id,
                product: mapProductFromGql(coupon_product.product),
              })),
            excludedProducts: data.coupon_by_pk.coupon_products
              .filter(coupon_product => coupon_product.is_excluded)
              .map(coupon_product => ({
                id: coupon_product.id,
                productId: coupon_product.product_id,
                product: mapProductFromGql(coupon_product.product),
              })),
          },
    [data, error, loading],
  )

  return {
    coupon,
    loadingCoupon: loading,
    errorCoupon: error,
    refetchCoupon: refetch,
  }
}
