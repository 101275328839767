'use client'

import { CartProductProps, ProductCheckoutOptions } from '@havppen/types/src/checkout'
import { ProductType } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import { localStore } from '@havppen/utils/src/storageFactory'
import dayjs from 'dayjs'
import { useSearchParams } from 'next/navigation'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { trpc } from 'src/helpers/trpc'
import {
  getCouponCode,
  getReferralCode,
  useDeleteMemberCartProducts,
  useInsertMemberCartProducts,
  useMemberCartProducts,
  useUpdateMemberCartProductOptions,
  useUpdateMemberCartProductQuantity,
} from 'src/hooks/checkout'
import { useCoupons } from 'src/hooks/coupon'
import { useAuth } from './AuthContext'

const CART_LOCAL_STORAGE_KEY = 'cart.products'

const CartContext = React.createContext<{
  loadingCartProducts: boolean
  cartProducts: CartProductProps[]
  cartProductCount: number
  checkoutProductIds: string[]
  point: number
  discountId: string | null
  referralCode: string | null
  couponCode: string | null
  setCheckoutProductIds?: React.Dispatch<React.SetStateAction<string[]>>
  setPoint?: React.Dispatch<React.SetStateAction<number>>
  setDiscountId?: React.Dispatch<React.SetStateAction<string | null>>
  setReferralCode?: React.Dispatch<React.SetStateAction<string | null>>
  setCouponCode?: React.Dispatch<React.SetStateAction<string | null>>
  checkIsProductInCart?: (productType: ProductType, productTarget: string) => boolean
  fetchCartProducts?: () => Promise<void>
  getCartProduct?: (productType: ProductType, productTarget: string) => CartProductProps | null
  addCartProduct?: (
    productId: string,
    quantity: number,
    productOptions: ProductCheckoutOptions | undefined,
    isQuantityPurchaseEnabled?: boolean,
  ) => Promise<boolean>
  updateCartProductQuantity?: (productId: string, quantity: number) => Promise<void>
  removeCartProducts?: (productIds: string[]) => Promise<void>
  removeAllCartProducts?: () => Promise<void>
}>({
  loadingCartProducts: true,
  cartProducts: [],
  cartProductCount: 0,
  checkoutProductIds: [],
  discountId: null,
  referralCode: null,
  couponCode: null,
  point: 0,
})

const getUnsyncLocalCartProducts = () => {
  const localCartProducts: CartProductProps[] = JSON.parse(localStore.getItem(CART_LOCAL_STORAGE_KEY) || '[]')
  return localCartProducts
}
const setUnsyncLocalCartProducts = (cartProducts: CartProductProps[]) => {
  localStore.setItem(CART_LOCAL_STORAGE_KEY, JSON.stringify(cartProducts))
}
const removeAllLocalCartProducts = () => {
  localStore.removeItem(CART_LOCAL_STORAGE_KEY)
}

export const CartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const searchParams = useSearchParams()

  const { isAuthenticated } = useAuth()
  const [cartProducts, setCartProducts] = useState<CartProductProps[]>([])
  const [checkoutProductIds, setCheckoutProductIds] = useState<string[]>([])
  const [discountId, setDiscountId] = useState<string | null>(null)
  const [referralCode, setReferralCode] = useState<string | null>(() => getReferralCode())
  const [couponCode, setCouponCode] = useState<string | null>(() => getCouponCode())
  const [point, setPoint] = useState(0)

  const { loadingCartProducts, cartProducts: remoteCartProducts, refetchCartProducts } = useMemberCartProducts()
  const insertMemberCartProducts = useInsertMemberCartProducts()
  const updateMemberCartProductOptions = useUpdateMemberCartProductOptions()
  const updateMemberCartProductQuantity = useUpdateMemberCartProductQuantity()
  const deleteMemberCartProducts = useDeleteMemberCartProducts()

  useEffect(() => {
    const couponCode = searchParams?.get('couponCode')
    const referralCode = searchParams?.get('referral')

    if (couponCode) {
      const expiredAt = dayjs().add(7, 'days').toDate().getTime()
      localStore.setItem('couponCode', JSON.stringify({ couponCode, expiredAt }))
      setCouponCode(couponCode)
    }

    if (referralCode) {
      const expiredAt = dayjs().add(7, 'days').toDate().getTime()
      localStore.setItem('productReferralCode', JSON.stringify({ referralCode, expiredAt }))
      setReferralCode(referralCode)
    }
  }, [searchParams])

  const { refetchCoupons } = useCoupons('available', { skip: !couponCode || !isAuthenticated })
  const { mutateAsync: redeemDiscountMutate } = trpc.discount.redeem.useMutation({
    onSuccess: data => {
      if (data.code === 'SUCCESS' && data.result) {
        const discountId = data.result.discountId
        setDiscountId(discountId)

        try {
          localStore.removeItem('productReferralCode')
          localStore.removeItem('couponCode')
        } catch {}
      }
    },
  })
  useEffect(() => {
    if (isAuthenticated && couponCode) {
      redeemDiscountMutate({ types: ['Coupon'], code: couponCode }).then(() => {
        refetchCoupons()
      })
    }
  }, [isAuthenticated, couponCode, redeemDiscountMutate, refetchCoupons])

  useEffect(() => {
    if (couponCode) {
      window.gtag?.('set', { coupon: couponCode })
    }
  }, [couponCode])

  useEffect(() => {
    if (referralCode) {
      window.gtag?.('set', { referral_code: referralCode })
    }
  }, [referralCode])

  useEffect(() => {
    if (isAuthenticated) {
      const newCartProducts = remoteCartProducts.filter(cartProduct => {
        if (cartProduct.productId.includes('Course')) {
          return !cartProduct.isPurchased
        }
        return true
      })

      setCartProducts(newCartProducts)
    } else {
      const unsyncCartProducts = getUnsyncLocalCartProducts()
      if (unsyncCartProducts.length > 0) {
        setCartProducts(unsyncCartProducts)
      }
    }
  }, [isAuthenticated, remoteCartProducts])

  useEffect(() => {
    const syncCartProducts = async (cartProducts: CartProductProps[]) => {
      await insertMemberCartProducts(cartProducts)
      await refetchCartProducts()
      removeAllLocalCartProducts()
    }
    if (isAuthenticated) {
      const unsyncCartProducts = getUnsyncLocalCartProducts()
      if (unsyncCartProducts.length > 0) {
        syncCartProducts(unsyncCartProducts)
      }
    }
  }, [isAuthenticated, insertMemberCartProducts, refetchCartProducts])

  const removeAllCartProducts = useCallback(async () => {
    if (isAuthenticated) {
      await deleteMemberCartProducts()
    } else {
      removeAllLocalCartProducts()
      setCartProducts([])
    }
  }, [deleteMemberCartProducts, isAuthenticated])

  const fetchAllCartProducts = useCallback(async () => {
    await refetchCartProducts()
  }, [refetchCartProducts])

  const checkIsProductInCart = useCallback(
    (productType: ProductType, productTarget: string) =>
      cartProducts.some(cartProduct => cartProduct.productId === `${productType}_${productTarget}`),
    [cartProducts],
  )

  const getCartProduct = useCallback(
    (productType: ProductType, productTarget: string) =>
      cartProducts.find(cartProduct => cartProduct.productId === `${productType}_${productTarget}`) || null,
    [cartProducts],
  )

  const removeCartProducts = useCallback(
    async (productIds: string[]) => {
      if (productIds.length === 0) return

      if (isAuthenticated) {
        const cartProductIdsToBeRemoved = remoteCartProducts
          .filter(cartProduct => productIds.includes(cartProduct.productId))
          .map(cartProduct => cartProduct.id)
          .filter(notEmpty)
        await deleteMemberCartProducts(cartProductIdsToBeRemoved)
        await refetchCartProducts?.()
      } else {
        const unsyncCartProduct = getUnsyncLocalCartProducts()
        const remainingCartProducts = unsyncCartProduct.filter(
          cartProduct => !productIds.includes(cartProduct.productId),
        )
        setUnsyncLocalCartProducts(remainingCartProducts)
        setCartProducts(remainingCartProducts)
      }
    },
    [isAuthenticated, remoteCartProducts, deleteMemberCartProducts, refetchCartProducts],
  )

  const updateCartProductQuantity = useCallback(
    async (productId: string, quantity: number) => {
      if (isAuthenticated) {
        const repeatedCartProduct = remoteCartProducts.find(cartProduct => cartProduct.productId === productId)
        if (repeatedCartProduct?.id) {
          await updateMemberCartProductQuantity(repeatedCartProduct.id, quantity)
          await refetchCartProducts?.()
        }
      } else {
        const unsyncCartProduct = getUnsyncLocalCartProducts()
        const repeatedCartProduct = unsyncCartProduct.find(cartProduct => cartProduct.productId === productId)
        const newCartProducts = unsyncCartProduct.filter(cartProduct => cartProduct.productId !== productId)

        newCartProducts.push({
          productId,
          quantity,
          options: {
            ...repeatedCartProduct?.options,
          },
        })
        setUnsyncLocalCartProducts(newCartProducts)
        setCartProducts(newCartProducts)
      }
    },
    [isAuthenticated, refetchCartProducts, remoteCartProducts, updateMemberCartProductQuantity],
  )

  return (
    <CartContext.Provider
      value={{
        loadingCartProducts,
        cartProducts,
        cartProductCount: cartProducts.length,
        checkoutProductIds,
        point,
        discountId,
        referralCode,
        couponCode,
        setCheckoutProductIds,
        setPoint,
        setDiscountId,
        setReferralCode,
        setCouponCode,
        fetchCartProducts: fetchAllCartProducts,
        checkIsProductInCart,
        getCartProduct,
        addCartProduct: async (productId, quantity, productOptions, isQuantityPurchaseEnabled = false) => {
          const cartProductOptions = productOptions?.[productId]

          if (isAuthenticated && !loadingCartProducts) {
            const repeatedCartProduct = remoteCartProducts.find(cartProduct => cartProduct.productId === productId)
            if (!repeatedCartProduct) {
              await insertMemberCartProducts([{ productId, quantity, options: cartProductOptions }])
            }

            if (repeatedCartProduct?.id) {
              const newQuantity = isQuantityPurchaseEnabled ? repeatedCartProduct.quantity + quantity : 1
              await updateMemberCartProductQuantity(repeatedCartProduct.id, newQuantity)
              if (cartProductOptions) {
                await updateMemberCartProductOptions(repeatedCartProduct.id, cartProductOptions)
              }
            }

            await refetchCartProducts()

            return isQuantityPurchaseEnabled ? false : Boolean(repeatedCartProduct)
          } else {
            const unsyncCartProduct = getUnsyncLocalCartProducts()
            const repeatedCartProduct = unsyncCartProduct.find(cartProduct => cartProduct.productId === productId)
            const newCartProducts = unsyncCartProduct.filter(cartProduct => cartProduct.productId !== productId)

            newCartProducts.push({
              productId,
              quantity: cartProductOptions?.quantity
                ? (repeatedCartProduct?.options?.quantity || 1) + cartProductOptions.quantity
                : 1,
              options: {
                ...cartProductOptions,
              },
            })
            setUnsyncLocalCartProducts(newCartProducts)
            setCartProducts(newCartProducts)

            return isQuantityPurchaseEnabled ? false : Boolean(repeatedCartProduct)
          }
        },
        updateCartProductQuantity,
        removeCartProducts,
        removeAllCartProducts,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext)
export default CartContext
